<template>
  <div>
    <vs-checkbox
      class="table-status-icon"
      v-model="checkBox1"
      @click="selectExpense"
      style="padding-top: 12px"
      :disabled="params.data.vendorSyncStatus == '0' ? true : false"
    ></vs-checkbox>
  </div>
</template>

<script>
import Vue from "vue";
//
import _ from "lodash";

export default Vue.extend({
  data() {
    return {
      checkBox1: false,
      newData: [],
    };
  },
  watch: {},
  beforeMount() {
    this.newData = this.params.data;
    console.log(
      "this.newDatathis.newDatathis.newDatathis.newData",
      this.newData
    );
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodale" + this.params.data.purchase_id ||
        "popmodalr" + this.params.data.purchase_id
    );
    // _id.setAttribute("style", "display: none;");
  },
  computed: {},
  methods: {
    selectExpense() {
      console.log("fdsfdsf", this.checkBox1);
      setTimeout(() => {
        eventBus.$emit("selectedExpense", this.params.data.purchase_id);
      }, 1);
    },
  },
  mounted() {
    // this.purchaseData = this.params.data;
    // eventBus.$on("PDFFLAARRAYRemove" + this.params.data.fla_id, (e) => {
    //   this.checkBox1 = this.checkBox1 ? false : true;
    // });
  },
});
</script>
<style>
.po-popup .vs-popup--content {
  height: 100%;
}
</style>
