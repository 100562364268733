<template>
  <div class="">
    <h5 class="text-center">
      <small>Expenses Created By : </small>{{ purchaseData.user_name }}<br />
      <small>Expenses Created on : </small>{{ purchaseData.created_date }}
    </h5>
    <vs-card>
      <vs-tabs>
        <vs-tab label="Logs">
          <h3 v-if="!logs">No Logs to Show!</h3>

          <div class="flex" v-else>
            <vs-card
              v-for="(log, index) in logs"
              :key="'log' + index"
              class="w-1/3"
            >
              <div slot="header">
                <h4>
                  Resubmitted By: <b>{{ log.user_name }}</b>
                </h4>
                <p class="text-lg">Resubmitted On: {{ log.modified_date }}</p>
              </div>

              <div class="">
                <p>
                  <span class="text-base mr-3">VHC Code:</span>
                  <span class="text-lg font-bold">{{ log.VHC_code }}</span>
                </p>
                <hr />

                <p>
                  <span class="text-base mr-3">Category:</span>
                  <span class="text-lg font-bold">{{
                    log.parentCategory
                  }}</span>
                </p>
                <hr />

                <p>
                  <span class="text-base mr-3">Sub-Category:</span>
                  <span class="text-lg font-bold">{{
                    log.sub_category_name
                  }}</span>
                </p>
                <hr />

                <p>
                  <span class="text-base mr-3">Invoice Number:</span>
                  <span class="text-lg font-bold">{{
                    log.invoice_number
                  }}</span>
                </p>
                <hr />

                <p>
                  <span class="text-base mr-3">Invoice Date:</span>
                  <span class="text-lg font-bold">{{ log.invoice_date }}</span>
                </p>
                <hr />

                <p>
                  <span class="text-base mr-3">Payment Due Date:</span>
                  <span class="text-lg font-bold">{{
                    log.payment_due_date
                  }}</span>
                </p>
                <hr />

                <p>
                  <span class="text-base mr-3">Amount:</span>
                  <span class="text-lg font-bold">{{ log.price }}</span>
                </p>
                <hr />

                <p>
                  <span class="text-base mr-3">Tax:</span>
                  <span class="text-lg font-bold">{{ log.gst_amount }}</span>
                </p>
                <hr />

                <p>
                  <span class="text-base mr-3">Total Amount:</span>
                  <span class="text-lg font-bold">{{ log.total_amount }}</span>
                </p>
                <hr />

                <p>
                  <span class="text-base mr-3">Description:</span>
                  <span class="text-lg font-bold">
                    {{ log.purchase_description }}
                  </span>
                </p>
                <hr />

                <vs-table :data="log.purchase_mapping_detail">
                  <template slot="header">
                    <h3>
                      Approval Details
                    </h3>
                  </template>
                  <template slot="thead">
                    <vs-th class="w-24">
                      Level
                    </vs-th>
                    <vs-th>
                      Description
                    </vs-th>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr
                      :state="
                        indextr == 2 || indextr == 5
                          ? 'success'
                          : indextr == 6
                          ? 'danger'
                          : null
                      "
                      :key="indextr"
                      v-for="(tr, indextr) in data"
                    >
                      <vs-td :data="data[indextr].level">
                        <span>{{ data[indextr].level }}</span>
                      </vs-td>
                      <vs-td :data="data[indextr].description">
                        {{ data[indextr].description }}
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
              <vs-button
                class="block mx-auto"
                size="small"
                v-if="log.purchase_image"
                @click.prevent.native="showImages(log)"
              >
                View Images
              </vs-button>
            </vs-card>
          </div>
        </vs-tab>

        <vs-tab label="Logs for Uploaded Documents After Approval">
          <h3 v-if="!document_logs">No Logs to Show!</h3>

          <div class="flex" v-else>
            <vs-card
              v-for="(log, index) in document_logs"
              :key="'document_log' + index"
              class="w-1/3"
            >
              <div slot="header">
                <h4>
                  Document Uploaded After approval By:
                  <b>{{ log.user_name }}</b>
                </h4>
                <p class="text-lg">Uploaded On: {{ log.modified_date }}</p>
              </div>

              <div class="">
                <p>
                  <span class="text-base mr-3">VHC Code:</span>
                  <span class="text-lg font-bold">{{ log.VHC_code }}</span>
                </p>
                <hr />

                <p>
                  <span class="text-base mr-3">User Comment:</span>
                  <span class="text-lg font-bold">
                    {{ log.purchase_mapping }}
                  </span>
                </p>
                <hr />

                <vs-button
                  class="block mx-auto"
                  size="small"
                  v-if="log.purchase_image"
                  @click.prevent.native="showImages(log)"
                >
                  View Images
                </vs-button>
              </div>
            </vs-card>
          </div>
        </vs-tab>
      </vs-tabs>
    </vs-card>

    <vs-popup
      title="Attachments"
      v-if="purchase"
      :active.sync="showDocsPopup"
      style="z-index: 99999 !important;"
    >
      <Attachments :data="purchase" />
    </vs-popup>
  </div>
</template>

<script>
import purchaseOrderService from "@/services/purchaseOrderService.js";
import Attachments from "./Attachments.vue";

export default {
  components: { Attachments },

  props: {
    purchaseData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      logs: [],
      document_logs: [],
      showDocsPopup: false,
      purchase: null,
    };
  },

  methods: {
    getExpenseLog() {
      purchaseOrderService
        .showLogs(this.purchaseData.purchase_id)
        .then((response) => {
          this.logs = response.data.datanew.filter(
            (data) => data.purchase_status == 2
          );
          this.document_logs = response.data.datanew.filter(
            (data) => data.purchase_status == 1
          );

          if (this.logs && this.logs.length > 0) {
            this.logs = this.logs.map((log) => ({
              ...log,
              purchase_mapping_detail: JSON.parse(log.purchase_mapping),
            }));
          }
        })
        .catch((error) => console.log("Error => ", error.message));
    },

    showImages(data) {
      this.purchase = data;
      this.showDocsPopup = true;
    },
  },

  mounted() {
    this.getExpenseLog();
  },
};
</script>

<style></style>
