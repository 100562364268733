var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-carousel',{staticStyle:{"text-shadow":"1px 1px 2px #333"},attrs:{"id":"carousel","controls":"","indicators":"","background":"#ababab","img-width":"1024","img-height":"700"},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.attachments),function(attachment,index){return _c('b-carousel-slide',{key:'attachment' + index,attrs:{"img-blank":""}},[_c('div',{staticClass:"flex flex-col items-center"},[(
            _vm.imageExtensions.indexOf(
              attachment.split('.')[attachment.split('.').length - 1]
            ) >= 0
          )?_c('b-img',{attrs:{"width":"250","src":attachment,"fluid":"","alt":"Purchase Attachment"}}):(attachment.split('.').slice(-1)[0] == 'pdf')?_c('img',{staticClass:"block mx-auto h-32",attrs:{"src":require("@/assets/images/pdf-icon.png")}}):(
            attachment.split('.').slice(-1)[0] == 'docx' ||
              attachment.split('.').slice(-1)[0] == 'doc'
          )?_c('img',{staticClass:"block mx-auto ",attrs:{"src":require("@/assets/images/docx_icon.png")}}):_c('img',{staticClass:"block mx-auto h-32",attrs:{"src":require("@/assets/images/excelLogo.png")}}),_c('vs-button',{staticClass:"mt-10",attrs:{"color":"primary","type":"filled","href":attachment,"target":"_blank"}},[_vm._v("\n          Preview\n        ")])],1)])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }