<!-- =========================================================================================
    File Name: projectPosList.vue
    Description: PURCHASE ORDER LIST
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard
    Author:
    Author URL:
========================================================================================== -->

<template>
  <div>
    <!-- PURCHASE ORDER LIST -->

    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4>Credit Note List</h4>
          <div class="flex flex-wrap justify-end items-center">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div
              class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
            >
              <!-- <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
              <vs-button
                class="mb-4 md:mb-0 text-hover-white"
                @click="gridApi.exportDataAsCsv()"
              >Export Expense List</vs-button>-->
              <!-- <vx-tooltip text="Export Excel" v-if="showTally==1">
                <a
                  :href="
                    excelurl +
                      `/v2/creditNote/excel/download?organization_id=${organization_id}&token=${temp_token}&project_id=${
                        this.projectID
                      }&search=${searchQuery}&tally_isActive=1`
                  "
                  target="_blank"
                >
                  <vs-button class="px-4 mr-2">
                    <span class="d-inline-flex pr-5 text-white">Tally Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip> -->
              <!-- <vx-tooltip text="Sync to Tally">
                <vs-button class="px-4 mr-2" @click.native="syncVendor()">
                    <span class="d-inline-flex pr-5 text-white">Sync to Tally</span>
                  
                  </vs-button>
              </vx-tooltip> -->
              <vx-tooltip text="Export Excel">
                <a
                  :href="
                    excelurl +
                    `/v2/creditNote/excel/download?organization_id=${organization_id}&token=${temp_token}&project_id=${this.projectID}&search=${searchQuery}`
                  "
                  target="_blank"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>
            </div>
          </div>
        </div>
        <ag-grid-vue
          @cell-clicked="onCellClicked"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="poOrders"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>

        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
    <div v-if="showimageModal == true">
      <image-pop-up :imagePopParamsData="imagePopParamsData"></image-pop-up>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";

import editPoOrder from "./editPoOrder.vue";
import imagePopUp from "./imagePopUp.vue";
import priority from "./PosPriority.vue";
import { BASEURL } from "@/config/index.js";
import purchaseOrderService from "@/services/purchaseOrderService.js";
const VxTour = () => import("@/components/VxTour.vue");

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import downloadInvoiceVue from "./downloadInvoice.vue";
import axios from "axios";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
    imagePopUp,
  },
  data() {
    return {
      showPoOrderModal: false,
      showPoOrder1Modal: false,
      editPoParamsData: null,
      editPo1ParamsData: null,
      imagePopParamsData: null,
      expDetailsParamsData: null,
      showExpDetailsModal: false,
      showimageModal: false,
      showTally: 0,
      totalPages: 0,
      excelurl: BASEURL,
      poOrders: [],
      User: "Select User",
      popupActive: false,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      columnDefs: [
        {
          headerName: "Credit Invoice",
          field: "credit_invoice_no",
          width: 165,
          filter: true,
        },
        {
          headerName: "Expense Name",
          field: "vendor_name",
          minWidth: 200,
          filter: true,
          cellRenderer: function (params) {
            return `<div class="">${params.data.vendor_name} ${
              params.data.purchase_invoice_number == null
                ? ""
                : " (" + params.data.purchase_invoice_number + ") "
            }<div>`;
          },
        },
        {
          headerName: "Created By",
          field: "credit_note_creator",
          minWidth: 200,
          maxWidth: 300,
          filter: true,
        },
        // {
        //   headerName: "Description",
        //   field: "purchase_description",
        //   filter: true,
        //   width: 275,
        // },
        // {
        //   headerName: "Category",
        //   field: "category_name",
        //   filter: true,
        //   width: 275,
        // },
        // {
        //   headerName: "Sub Category",
        //   field: "sub_category_name",
        //   filter: true,
        //   width: 275,
        // },

        // {
        //   headerName: "City",
        //   field: "city_name",
        //   filter: true,
        //   width: 300,
        // },

        // {
        //   headerName: "Country",
        //   field: "country_name",
        //   filter: true,
        //   width: 150,
        // },

        {
          headerName: "Credit Amount",
          field: "credit_amount",
          filter: true,
          width: 150,
        },
        {
          headerName: "Tax Amount",
          field: "gst_amount",
          filter: true,
          width: 150,
        },
        {
          headerName: "Total Amount",
          field: "total_amount",
          filter: true,
          width: 150,
        },

        // {
        //   headerName: "Currency",
        //   field: "currency",
        //   filter: true,
        //   width: 150
        // },
        {
          headerName: "Currency",
          field: "currency_id",
          filter: true,
          width: 145,
        },

        {
          headerName: "Multiple Images",
          field: "credit_image",
          filter: true,
          width: 200,
          sortable: false,
          cellRenderer: function (params) {
            return params.data.hasOwnProperty("credit_image") &&
              params.data.credit_image != "-"
              ? '<span class="text-center table-edit-icon"> <i class="text-center material-icons">info</i> </span>'
              : '    <span class="text-center">No Images</span>';
          },
          //  cellRendererFramework: imagePopUp,
        },

        // {
        //   headerName: "Download",
        //   field: "purchase_id",
        //   filter: true,
        //   sortable: false,
        //   width: 140,
        //   cellRendererFramework: downloadInvoiceVue
        // }
      ],
      contacts: contacts,
      projectID: null,
      organization_id: "",
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.purchaseOrderList(val);
          return val;
        } else return 1;
      },
    },
  },
  beforeMount() {
    this.organization_id = localStorage.getItem("OrganizationID");

    const { projectID } = this.$route.params;
    this.projectID = projectID || null;
    eventBus.$on("refreshPOList", (e) => {
      this.purchaseOrderList(this.currentPage);
    });
  },
  methods: {
    onCellClicked(params) {
      console.log(params);

      if (
        params.colDef.field == "credit_image" &&
        params.data.credit_image &&
        params.data.credit_image != "-"
      ) {
        this.showimageModal = false;
        this.imagePopParamsData = params;
        setTimeout(() => {
          this.showimageModal = true;
        }, 300);
      }
    },
    syncVendor() {
      this.$vs.loading();
      axios
        .get(`${BASEURL}/v2/tally/syncVoucherToTally`)
        .then((response) => {
          this.$vs.notify({
            // title: "Duplicate entries have not been imported.",
            title: "Updated!",
            text: "Sync Successfully",
            // time:8000,
            time: 2000,
            iconPack: "feather",
            icon: "check_box",
            color: "success",
          });
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
        });
    },
    purchaseOrderList: function (currentPage) {
      let payload = {
        limit: this.paginationPageSize,
        type: 0,
        page_no: currentPage,
      };
      purchaseOrderService
        .getCreditNoteList(this.projectID, payload)
        .then((response) => {
          const { data } = response;
          if (!data.err || data.Status == true) {
            this.poOrders = data.data;
            this.totalPages = data.pagination.last_page;
            this.autoSizeAll();
          } else {
          }

          if (data.hasOwnProperty("tally_isActive")) {
            this.showTally = data.tally_isActive;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    autoSizeAll() {
      var allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
    },
  },
  mounted() {
    this.purchaseOrderList(this.currentPage);
    this.gridApi = this.gridOptions.api;
  },
};
</script>

<style scoped>
.po-user-card {
  width: 130px;
  height: auto;
  padding: 5px 5px 0px 5px;
  margin-bottom: 1rem;
  border-radius: 8px;
  border: 1px solid #e5e8eb;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
</style>
