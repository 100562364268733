<template>
  <div class="">
    <vx-card class="mb-5">
      <h5>Filters</h5>
      <div class="flex flex-col sm:flex-row">
        <div class="w-full sm:w-1/5 sm:pr-2 mb-2 sm:mb-0">
          <vs-input
            v-model="filters.VHC_code"
            type="text"
            :placeholder="
              is_var == 1
                ? 'VAR Code'
                : is_var == 2
                ? 'CN Code'
                : is_var == 3
                ? 'PCR Code'
                : 'VHC Code'
            "
            class="w-full"
          />
        </div>

        <div class="w-full sm:w-1/5 sm:pr-2 mb-2 sm:mb-0">
          <multiselect
            v-model="filters.user"
            :options="projectUsers"
            label="user_name"
            track-by="user_id"
            placeholder="Select User"
            open-direction="bottom"
            select-label=""
            deselect-label=""
          />
        </div>

        <div class="w-full sm:w-1/5 sm:pr-2 mb-2 sm:mb-0" v-if="is_var != 3">
          <multiselect
            v-model="filters.priority"
            :options="priorityList"
            label="priority_name"
            track-by="priority_id"
            placeholder="Select Priority"
            open-direction="bottom"
            select-label=""
            deselect-label=""
          />
        </div>

        <div class="w-full sm:w-1/5 sm:pr-2 mb-2 sm:mb-0">
          <multiselect
            v-model="filters.vendor"
            :options="vendorList"
            label="vendor_name"
            track-by="vendor_id"
            placeholder="Select Vendor"
            open-direction="bottom"
            select-label=""
            deselect-label=""
          />
        </div>

        <div class="w-full sm:w-1/5 flex flex-col sm:flex-row">
          <vs-button
            class="w-full sm:w-32 sm:mr-2 mb-2 sm:mb-0"
            @click="refreshList"
          >
            Search
          </vs-button>
          <vs-button class="w-full sm:w-32" @click="clearFilters"
            >Clear</vs-button
          >
        </div>
      </div>
    </vx-card>

    <vx-card>
      <div class="flex flex-col sm:flex-row items-center justify-between">
        <div class="heading w-full sm:w-1/3">
          <h4>{{ listHeading }}</h4>
        </div>
        <div
          class="w-full sm:w-2/3 flex flex-col sm:flex-row items-center justify-between sm:justify-end"
        >
          <!-- Search input -->
          <vs-input
            v-model="filters.search"
            @input="statusChanged"
            placeholder="Search..."
            class="w-full sm:w-auto sm:mr-2 mb-2 sm:mb-0"
            icon-no-border
          />
          <!-- Status Dropdown -->
          <multiselect
            label="text"
            value="value"
            preselectFirst
            v-model="filters.selectedStatus"
            @select="statusChanged"
            name="Project Name"
            :options="expenseStatusOptions"
            :searchable="true"
            :allow-empty="false"
            placeholder=""
            deselect-label=""
            autocomplete="off"
            class="w-full sm:w-48 sm:mr-2 mb-2 sm:mb-0"
            openDirection="bottom"
          />

          <!-- Export Button -->
          <vs-button
            icon="file_upload"
            class="w-full sm:w-24 mb-2 sm:mb-0"
            icon-after
            @click="exportExcelClicked(123)"
          >
            Export
          </vs-button>
        </div>
      </div>

      <!-- Table -->
      <div class="mt-5 text-center">
        <b-table
          :items="listData"
          :fields="tableFields"
          ref="expenseList"
          thead-class="table-head"
          primary-key="purchase_id"
          responsive
          sticky-header
          outlined
          bordered
          hover
          show-empty
          :resizable="true"
          :drag-column="true"
        >
          <template #empty="scope">
            <h4 class="text-bold text-info ">
              {{ scope.emptyText }}
            </h4>
          </template>
          <template #head()="data">
            <div class="w-32 text-lg">{{ data.label }}</div>
          </template>
          <template #head(purchase_status)="data">
            <div class="w-48 text-lg">{{ data.label }}</div>
          </template>
          <template #head(po_name)="data">
            <div class="w-64 text-lg">{{ data.label }}</div>
          </template>
          <template #head(total_amount)="data">
            <div class="w-48 text-lg">{{ data.label }}</div>
          </template>
          <template #head(raised_from_var)="data">
            <div class="w-48 text-lg">{{ data.label }}</div>
          </template>
          <template #head(VHC_code)="data">
            <div class="w-64 text-lg">{{ data.label }}</div>
          </template>
          <template #head(created_date)="data">
            <div class="w-48 text-lg">{{ data.label }}</div>
          </template>
          <template #head(is_verified)="data">
            <div class="w-48 text-lg">{{ data.label }}</div>
          </template>

          <template #cell(purchase_actions)="data">
            <div class="flex items-center">
              <vs-button
                radius
                color="primary"
                type="gradient"
                icon="edit"
                @click="() => editItem(data.item)"
                class="mr-1"
                size="small"
                title="Edit"
              />
              <vs-button
                v-if="
                  data.item.purchase_status == 2 &&
                    (userRole.toLowerCase() == 'admin' ||
                      (permissionPageAccessArry &&
                        permissionPageAccessArry.includes(
                          PermissionVariables.DeleteExpenses
                        )))
                "
                radius
                color="danger"
                type="gradient"
                icon="delete"
                @click="() => deleteItem(data.item)"
                class="mr-1"
                size="small"
                title="Delete"
              />
            </div>
          </template>

          <template #cell(purchase_status)="data">
            <div
              :class="
                data.item.purchase_status == 1
                  ? 'text-success'
                  : data.item.purchase_status == 2
                  ? 'text-danger'
                  : 'text-warning'
              "
              class="font-bold w-"
            >
              {{
                data.item.purchase_status == 1
                  ? "Approved"
                  : data.item.purchase_status == 2
                  ? "Rejected"
                  : "Pending"
              }}
            </div>
          </template>

          <template #cell(log)="data">
            <div class="flex items-center justify-center">
              <vs-button
                :disabled="!data.item.showlogs >= 1"
                radius
                color="primary"
                type="gradient"
                icon="visibility"
                @click="() => showLog(data.item)"
                size="small"
                title="View Logs"
              />
            </div>
          </template>

          <template #cell(purchase_details)="data">
            <div class="flex items-center justify-center">
              <vs-button
                radius
                color="primary"
                type="gradient"
                icon="visibility"
                @click="() => showDetail(data.item)"
                size="small"
                title="View Details"
              />
            </div>
          </template>

          <template #cell(raised_from_var)="data">
            <div class="flex items-center justify-center">
              {{
                data.item.raised_from_var && data.item.raised_from_var == 1
                  ? "Against VAR"
                  : data.item.raised_from_var && data.item.raised_from_var == 2
                  ? "Against PCR"
                  : "Normal Expense"
              }}
            </div>
          </template>

          <template #cell(priority_name)="data">
            <div class="flex items-center justify-center">
              <b-badge
                pill
                class="text-lg"
                :variant="
                  data.item.priority_name &&
                  data.item.priority_name.toLowerCase() == 'high'
                    ? 'danger'
                    : data.item.priority_name &&
                      data.item.priority_name.toLowerCase() == 'medium'
                    ? 'warning'
                    : 'secondary'
                "
                >&nbsp;</b-badge
              >
            </div>
          </template>

          <template #cell(purchase_image)="data">
            <div class="flex items-center justify-center">
              <vs-button
                radius
                color="primary"
                type="gradient"
                icon="edit"
                @click="() => showAttachments(data.item)"
                size="small"
                title="View Documents"
              />
            </div>
          </template>

          <template #cell(download)="data">
            <vs-button
              :disabled="data.item.purchase_status != 1"
              radius
              color="primary"
              type="gradient"
              icon="file_download"
              @click="() => downloadPDF(data.item)"
              class="mx-auto"
              size="small"
              title="Download"
            />
          </template>

          <template #cell(is_verified)="data">
            <div
              class="flex items-center justify-center font-bold"
              :class="
                data.item.is_verified == 1 ? 'text-success' : 'text-warning'
              "
            >
              {{ data.item.is_verified == 1 ? "Verified" : "Unverified" }}
            </div>
          </template>
        </b-table>

        <div class="flex items-center justify-between">
          <vs-pagination
            :total="pagination.totalPages"
            v-model="pagination.currentPage"
          />

          <div class="mb-2">
            Show&nbsp;
            <multiselect
              :options="limits"
              v-model="limit"
              track-by="value"
              label="label"
              selected-label=""
              select-label=""
              deselect-label=""
              class="inline-block w-16"
              size="sm"
            />&nbsp;Entries Per Page
          </div>
        </div>
      </div>

      <!-- Popups -->
      <div class="">
        <!-- Delete Confirmation -->
        <vs-popup
          class="p-2"
          title="Warning"
          v-if="confirmDeletePopup"
          :active.sync="confirmDeletePopup"
        >
          <div class="text-center">
            <h5>Are you sure you want to delete the expense?</h5>
            <div class="vx-row mt-5">
              <div class="vx-col mx-auto">
                <vs-button
                  color="danger"
                  type="filled"
                  @click="deletePurchase()"
                  class="mr-3"
                >
                  Yes
                </vs-button>

                <vs-button
                  color="primary"
                  type="filled"
                  @click.native="cancelDeleting()"
                >
                  No
                </vs-button>
              </div>
            </div>
          </div>
        </vs-popup>

        <!-- Edit/Approve/Reject -->
        <vs-popup
          title="Edit Expense"
          v-if="showActionPopup"
          :active.sync="showActionPopup"
          fullscreen
        >
          <!-- <ExpenseAction :purchaseData="selectedPurchase" /> -->
          <EditPoOrder
            v-if="showActionPopup"
            :editPoParamsData="{ data: selectedPurchase }"
            :modalCurrentPage="1"
            @closeEdit="
              () => {
                this.showActionPopup = false;
              }
            "
          />
        </vs-popup>

        <!-- Logs -->
        <vs-popup
          title="Expense Logs"
          v-if="showLogsPopup"
          :active.sync="showLogsPopup"
          fullscreen
        >
          <ExpenseLog :purchaseData="selectedPurchase" />
        </vs-popup>

        <!-- Detauls -->
        <vs-popup
          :title="
            selectedPurchase.is_var == 1
              ? 'VAR Details'
              : selectedPurchase.is_var == 2
              ? 'CN Details'
              : selectedPurchase.is_var == 3
              ? 'PCR Details'
              : 'Expense Details'
          "
          :active.sync="showDetailPopup"
        >
          <ExpenseDetail :data="selectedPurchase" />
        </vs-popup>

        <!-- Attachments -->
        <vs-popup title="Attachments" :active.sync="showDocsPopup">
          <Attachments :data="selectedPurchase" />
        </vs-popup>
      </div>
    </vx-card>
  </div>
</template>

<script>
// Components
import { AgGridVue } from "ag-grid-vue";
import { BBadge } from "bootstrap-vue";
import { BTable } from "bootstrap-vue";
// import ExpenseAction from "./ExpenseAction.vue";
import ExpenseLog from "./ExpenseLog.vue";
import ExpenseDetail from "./ExpenseDetail.vue";
import Attachments from "./Attachments.vue";
import EditPoOrder from "./editPoOrder.vue";

// Services
import purchaseOrderService from "@/services/purchaseOrderService.js";
import UserService from "@/services/UserService.js";
import MethodService from "@/services/methodService.js";
import ContractService from "@/services/ContractService.js";

// others
import { BASEURL } from "@/config/index.js";
import PermissionVariables from "@/services/UserManagementVaraibles.js";

export default {
  components: {
    AgGridVue,
    BBadge,
    BTable,
    // ExpenseAction,
    ExpenseLog,
    ExpenseDetail,
    Attachments,
    EditPoOrder,
  },

  props: {
    listHeading: {
      type: String,
      default: "Expense List",
    },
    is_var: {
      type: Number,
      default: 0,
    },
    project_id: {
      type: Number,
      required: true,
    },
  },

  computed: {},

  watch: {
    limit() {
      this.pagination.currentPage = 1;
      this.refreshList();
    },
    "pagination.currentPage"(newVal) {
      this.getListData(newVal);
    },
    showActionPopup(newVal) {
      if (!newVal) {
        this.refreshList();
      }
    },
  },

  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : [],
      PermissionVariables: PermissionVariables,
      temp_token: localStorage.getItem("TempToken"),
      BASEURL: BASEURL,
      organizationID: null,
      filters: {
        search: "",
        selectedStatus: null,
        VHC_code: "",
        user: null,
        priority: null,
        vendor: null,
      },
      expenseStatusOptions: [
        { value: null, text: "All" },
        { value: 0, text: "Pending" },
        { value: 1, text: "Approved" },
        { value: 2, text: "Rejected" },
      ],
      limits: [
        { value: 10, label: 10 },
        { value: 25, label: 25 },
        { value: 50, label: 50 },
        { value: 100, label: 100 },
        { value: "all", label: "All" },
      ],
      limit: { value: 10, label: 10 },
      listData: [],
      tableFields: [
        {
          label: "Action",
          key: "purchase_actions",
          thClass: "table-head",
        },
        {
          label:
            (this.is_var == 1
              ? "VAR"
              : this.is_var == 2
              ? "CN"
              : this.is_var == 3
              ? "PCR"
              : "Expense") + " Status",
          key: "purchase_status",
          thClass: ["table-head"],
        },
        {
          label: "Log",
          key: "log",
          class: "w-48",
          thClass: "table-head",
        },
        {
          label: "Name",
          key: "po_name",
          thClass: "table-head",
        },
        {
          label: "User",
          key: "user_name",
          thClass: "table-head",
        },
        {
          label: "City",
          key: "city_name",
          thClass: "table-head",
        },
        {
          label: "Details",
          key: "purchase_details",
          thClass: "table-head",
        },
        {
          label: "Total Amount",
          key: "total_amount",
          thClass: "table-head",
        },
        {
          label: "TDS Amount",
          key: "tds_amount",
          thClass: "table-head",
        },
        {
          label: "Paid Amount",
          key: "tally_paid_amount",
          thClass: "table-head",
        },

        {
          label:
            this.is_var == 1
              ? "VAR Code"
              : this.is_var == 2
              ? "CN Code"
              : this.is_var == 3
              ? "PCR Code"
              : "VHC Code",
          key: "VHC_code",
          thClass: "table-head",
        },
      ],
      pagination: {
        currentPage: 1,
        maxPageNumbers: 4,
        totalPages: 1,
      },

      selectedPurchase: {},
      showActionPopup: false,
      confirmDeletePopup: false,
      showLogsPopup: false,
      showDetailPopup: false,
      showDocsPopup: false,

      userRole: localStorage.getItem("UserRole"),

      projectUsers: [],
      priorityList: [],
      vendorList: [],
    };
  },

  methods: {
    statusChanged(selected) {
      this.refreshList();
    },
    exportExcelClicked(purchase_id) {
      let url =
        BASEURL +
        "/v2/purchase/excel/download" +
        "?organization_id=" +
        this.organizationID +
        "&token=" +
        this.temp_token +
        "&approver_id=0" +
        "&project_id=" +
        this.project_id +
        "&search=" +
        this.filters.search +
        "&is_var=" +
        this.is_var;

      console.log("url => ", url);

      window.open(url, "_blank");
    },
    editItem(purchase_info) {
      this.selectedPurchase = purchase_info;
      this.showActionPopup = true;
    },
    deleteItem(purchase_info) {
      this.selectedPurchase = purchase_info;
      this.confirmDeletePopup = true;
    },
    showLog(purchase_info) {
      this.selectedPurchase = purchase_info;
      this.showLogsPopup = true;
    },
    showDetail(purchase_info) {
      this.selectedPurchase = purchase_info;
      this.showDetailPopup = true;
    },
    showAttachments(purchase_info) {
      this.selectedPurchase = purchase_info;
      this.showDocsPopup = true;
    },
    downloadPDF(purchase_info) {
      let url =
        BASEURL +
        "/v2/purchase/download/" +
        purchase_info.purchase_id +
        "?organization_id=" +
        this.organizationID +
        "&token=" +
        this.temp_token;

      window.open(url, "_blank");
    },
    getListData(currentPage = 1) {
      // this.modalCurrentPage = currentPage;
      this.$vs.loading();
      let filters = {
        search: this.filters.search,
        vhc_code: this.filters.VHC_code || "",
        created_user_id: this.filters.user ? this.filters.user.user_id : null,
        priority: this.filters.priority
          ? this.filters.priority.priority_id
          : null,
        selectedVendor: this.filters.vendor
          ? this.filters.vendor.vendor_id
          : null,
      };
      this.listData = [];
      let payload = {
        is_var: this.is_var,
        type: this.is_var != 3 ? 0 : 2,
        purchaseStatus: this.filters.selectedStatus
          ? this.filters.selectedStatus.value
          : null,
        page_no: currentPage,
        limit: this.limit.value,
        ...filters,
      };

      purchaseOrderService
        .getAllPurchaseOrderByProject(this.project_id, payload)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (!data.Status || data.data.length <= 0) {
            return this.$vs.notify({
              title: "Warning!",
              text: "No data found!",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
          this.listData = data.data;
          this.pagination.totalPages = data.pagination.last_page;
          if (data.hasOwnProperty("tally_isActive")) {
            this.showTally = data.tally_isActive;
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    deletePurchase() {
      purchaseOrderService
        .deletePurchase(this.selectedPurchase.purchase_id)
        .then((response) => {
          if (!response.data.Status) {
            return this.$vs.notify({
              color: "warning",
              title: "Oops!",
              text: response.data.Message || "Error deleteing!",
            });
          }
          this.statusChanged();
          return this.$vs.notify({
            color: "danger",
            title: "Success",
            text: response.data.Message || "Deleted successfully.",
          });
        })
        .catch((error) =>
          this.$vs.notify({
            color: "warning",
            title: "Error",
            text: error.message || "Error deleteing!",
          })
        );

      this.cancelDeleting();
    },
    cancelDeleting() {
      this.confirmDeletePopup = false;
      this.selectedPurchase = {};
    },
    refreshList() {
      this.$nextTick(() => {
        this.getListData();
      });
    },
    clearFilters() {
      this.filters = {
        ...this.filters,
        VHC_code: "",
        user: null,
        priority: null,
        vendor: null,
      };
      this.refreshList();
    },

    getProjectMap() {
      UserService.getMapList({ project_id: this.project_id })
        .then((response) => {
          if (response.data.Status) {
            this.projectUsers = response.data.data;
          }
        })
        .catch((error) => console.log("Error => ", error.message));
    },

    getMasters() {
      MethodService.getAllMaster()
        .then((response) => {
          if (response.data.Status) {
            this.priorityList = response.data.data.priority;
          }
        })
        .catch((error) => console.log("Error => ", error.message));
    },

    getVendorList: function() {
      ContractService.getContractVendorNew()
        .then((response) => {
          if (response.data.Status) {
            this.vendorList = response.data.data;
          }
        })
        .catch((error) => console.log("Error => ", error.message));
    },
  },

  beforeMount() {
    // this.projectID = this.$route.params.projectID || null;
    this.organizationID = localStorage.getItem("OrganizationID") || null;

    if (this.is_var == 0) {
      this.tableFields.push({
        label: "Expense Type",
        key: "raised_from_var",
        thClass: "table-head",
      });
    }

    this.tableFields.push(
      {
        label: "Created On",
        key: "created_date",
        thClass: "table-head",
      },
      {
        label: "Priority",
        key: "priority_name",
        thClass: "table-head",
      },
      {
        label: "Attachments",
        key: "purchase_image",
        thClass: "table-head",
      },
      {
        label: "Download",
        key: "download",
        thClass: "table-head",
      },
      {
        label: "Verified Status",
        key: "is_verified",
        thClass: "table-head",
      }
    );
  },

  mounted() {
    this.getListData();
    this.getProjectMap();
    this.getMasters();
    this.getVendorList();
  },
};
</script>

<style>
.heading h4 {
  font-family: inherit;
  font-weight: bolder;
  line-height: 1.2;
  font-size: 33px;
  font-variant: all-small-caps;
  color: rgba(var(--vs-primary), 1);
}

.table-icon {
  height: 40px;
  width: 40px;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  background: rgba(var(--vs-primary), 1);
}

.table-head {
  color: #fff !important;
  font-size: 18px !important;
  font-weight: bold !important;
  background: linear-gradient(
    180deg,
    rgba(var(--vs-primary), 0.8),
    rgba(var(--vs-primary), 0.6)
  );
}
</style>
