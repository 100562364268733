<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card>
        <div class="flex flex-wrap">Filters By</div>
        <div class="flex flex-wrap items-center justify-end">
          <div class="w-full sm:w-1/2 md:w-1/4 lg:w-1/6 p-1">
            <vs-input
              type="text"
              placeholder="VHC Code"
              v-model="vhc_code"
              class="w-full"
            />
          </div>
          <div class="w-full sm:w-1/2 md:w-1/4 lg:w-1/6 p-1">
            <multiselect
              label="user_name"
              track-by="user_id"
              class="w-full"
              v-model="created_user_id"
              :options="BehalfOfList"
              @select="onSelectBehalf"
              select-label=""
              deselect-label=""
              open-direction="bottom"
              placeholder="Select User"
            />
          </div>
          <div class="w-full sm:w-1/2 md:w-1/4 lg:w-1/6 p-1">
            <multiselect
              label="priority_name"
              track-by="priority_id"
              class="w-full"
              v-model="priorityValue"
              :options="priority"
              select-label=""
              deselect-label=""
              open-direction="bottom"
              placeholder="Select Priority"
            />
          </div>
          <div class="w-full sm:w-1/2 md:w-1/4 lg:w-1/6 p-1">
            <multiselect
              label="vendor_name"
              track-by="vendor_id"
              class="w-full"
              v-model="selectedVendor"
              :options="vendorList"
              select-label=""
              deselect-label=""
              open-direction="bottom"
              placeholder="Select Vendor"
            />
          </div>
          <div class="w-full sm:w-1/2 md:w-1/4 lg:w-1/6 p-1">
            <vs-button class="w-full" @click="filterData">
              Search
            </vs-button>
          </div>
          <div class="w-full sm:w-1/2 md:w-1/4 lg:w-1/6 p-1">
            <vs-button class="w-full" @click="clearData">
              Clear
            </vs-button>
          </div>
        </div>
      </vx-card>
      <vx-card>
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4 class="sm:w-1/3">Expense List</h4>
          <div class="flex flex-wrap justify-end items-center">
            <div
              class="flex flex-wrap items-center justify-between ag-grid-table-actions-right sm:w-auto"
            >
              <ul class="list-inline px-4" v-if="isTally == 1">
                <li
                  class="list-inline-item font-semibold project-menu-dropdown"
                >
                  <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <span class="flex cursor-pointer font-semibold">
                      Tally Sync Options : {{ selectedTallyOption }}
                      <feather-icon
                        icon="ChevronDownIcon"
                        svgClasses="h-4 w-4"
                        class="ml-1"
                      />
                    </span>
                    <vs-dropdown-menu class="w-32">
                      <vs-dropdown-item
                        selected
                        @click="onSelectTallySyncOption('All')"
                      >
                        All
                      </vs-dropdown-item>
                      <vs-dropdown-item
                        @click="onSelectTallySyncOption('Approved')"
                      >
                        Approved
                      </vs-dropdown-item>
                      <vs-dropdown-item
                        @click="onSelectTallySyncOption('Synced')"
                      >
                        Synced
                      </vs-dropdown-item>
                      <vs-dropdown-item
                        @click="onSelectTallySyncOption('Unsynced')"
                      >
                        Unsynced
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </li>
              </ul>

              <vx-tooltip text="Sync to Tally" class="w-full sm:w-auto mb-2">
                <vs-button
                  :disabled="
                    selectedTallyOption == 'Unsynced' &&
                    selectedExpenseList.length > 0
                      ? false
                      : true
                  "
                  class="px-4 mr-2 w-full sm:w-auto"
                  @click.native="syncVendor()"
                >
                  <span class="d-inline-flex pr-5 text-white">
                    Sync to Tally
                  </span>
                </vs-button>
              </vx-tooltip>

              <div class="w-64 mb-2 sm:mr-2">
                <vs-input
                  v-model="search"
                  placeholder="Search..."
                  class="w-full"
                  icon-no-border
                />
              </div>
              <div class="w-48 mb-2 sm:mr-2">
                <multiselect
                  label="label"
                  track-by="value"
                  v-model="sortBy"
                  :options="sortByList"
                  @select="statusChanged"
                  placeholder="Sort By"
                  open-direction="bottom"
                  select-label=""
                  deselect-label=""
                  selected-label=""
                />
              </div>
              <div class="w-32 mb-2 sm:mr-2">
                <multiselect
                  label="text"
                  track-by="value"
                  preselectFirst
                  v-model="selectedStatus"
                  @select="statusChanged"
                  :options="expenseStatusOptions"
                  open-direction="bottom"
                  select-label=""
                  deselect-label=""
                  selected-label=""
                  autocomplete="off"
                />
              </div>

              <div class="w-32 mb-2 sm:mr-2">
                <multiselect
                  placeholder="Download"
                  :options="[
                    { label: 'Excel', value: 'excel' },
                    { label: 'PDF', value: 'pdf' },
                  ]"
                  label="label"
                  track-by="value"
                  open-direction="bottom"
                  select-label=""
                  deselect-label=""
                  selected-label=""
                  :closeOnSelect="false"
                  :searchable="false"
                  @select="downloadSelected"
                />
              </div>

              <!-- <vx-tooltip
                text="Export Excel"
                class="w-full sm:mr-2 sm:w-auto mb-2"
              >
                <a
                  :href="
                    excelurl +
                      `/v2/purchase/excel/download?organization_id=${organization_id}&token=${temp_token}&project_id=${projectID}&approver_id=0&search=${searchQuery}${filterForExcel}`
                  "
                  target="_blank"
                >
                  <vs-button class="px-4 w-full sm:w-auto">
                    <span class="d-inline-flex pr-5 text-white">Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip> -->
            </div>
          </div>
        </div>

        <ag-grid-vue
          :gridOptions="gridOptions"
          @cell-clicked="onCellClicked"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="poOrders"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="false"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        />
        <div class="flex items-center justify-between">
          <vs-pagination
            :total="totalPages"
            :max="maxPageNumbers"
            v-model="currentPage"
          />

          <div class="mb-2">
            Show&nbsp;
            <multiselect
              :options="limits"
              v-model="limit"
              track-by="value"
              label="label"
              selected-label=""
              select-label=""
              deselect-label=""
              class="inline-block w-16"
              size="sm"
            />&nbsp;Entries Per Page
          </div>
        </div>
      </vx-card>
    </div>
    <div v-if="showPoOrderModal == true">
      <edit-po-order
        :editPoParamsData="editPoParamsData"
        :modalCurrentPage="modalCurrentPage"
      />
    </div>

    <div v-if="showPoOrder1Modal == true && purchaseDate >= '1658471400'">
      <purchase-logs :editPo1ParamsData="editPo1ParamsData"></purchase-logs>
    </div>

    <div v-if="showPoOrder1Modal == true && purchaseDate < '1658471400'">
      <edit-po-order-1 :editPo1ParamsData="editPo1ParamsData"></edit-po-order-1>
    </div>

    <div v-if="showimageModal == true">
      <image-pop-up :imagePopParamsData="imagePopParamsData"></image-pop-up>
    </div>

    <div v-if="showExpDetailsModal == true">
      <expense-details
        :expDetailsParamsData="expDetailsParamsData"
      ></expense-details>
    </div>

    <div class="">
      <vs-popup class="p-2" title="Warning" :active.sync="confirmDeletePopup">
        <div class="text-center">
          <h5>Are you sure you want to delete the expense?</h5>
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <vs-button
                color="danger"
                type="filled"
                @click="deletePurchase()"
                class="mr-3"
              >
                Yes
              </vs-button>

              <vs-button
                color="primary"
                type="filled"
                @click.native="cancelDeleting()"
              >
                No
              </vs-button>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import editPoOrder from "./editPoOrder.vue";
import editPoOrder1 from "./editPoOrder1.vue";
import PurchaseLogs from "./purchaseLogs.vue";
import imagePopUp from "./imagePopUp.vue";
import priority from "./PosPriority.vue";
import expenseDetails from "./expenseDetails.vue";
import { BASEURL } from "@/config/index.js";
import purchaseOrderService from "@/services/purchaseOrderService.js";
const VxTour = () => import("@/components/VxTour.vue");
import multiSelectExpenses from "./multiSelectExpenses.vue";
import moment from "moment";
import VueSimpleSuggest from "vue-simple-suggest";
import VueSuggest from "vue-simple-suggest/lib";
import UserService from "@/services/UserService.js";
import MethodService from "@/services/methodService.js";
import ContractService from "@/services/ContractService.js";

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import downloadInvoiceVue from "./downloadInvoice.vue";
import axios from "axios";
import PermissionVariables from "@/services/UserManagementVaraibles.js";

export default {
  components: {
    AgGridVue,
    VxTour,
    editPoOrder,
    editPoOrder1,
    imagePopUp,
    expenseDetails,
    VueSuggest,
    VueSimpleSuggest,
    PurchaseLogs,
  },
  data() {
    return {
      sortBy: null,
      sortByList: [
        { value: "user_id", label: "User" },
        { value: "vendor_id", label: "Vendor" },
        { value: "city_id", label: "City" },
        { value: "vhc_code", label: "VHC Code" },
        { value: "priority", label: "Priority" },
        { value: "created_date", label: "Created Date" },
        { value: "modified_date", label: "Updated Date" },
      ],
      limits: [
        { value: 10, label: 10 },
        { value: 25, label: 25 },
        { value: 50, label: 50 },
        { value: 100, label: 100 },
        { value: "all", label: "All" },
      ],
      limit: { value: 10, label: 10 },
      search: "",
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : [],
      PermissionVariables: PermissionVariables,
      confirmDeletePopup: false,
      purchaseToDdelete: {},
      expenseStatusOptions: [
        { value: null, text: "All" },
        { value: 0, text: "Pending" },
        { value: 1, text: "Approved" },
        { value: 2, text: "Rejected" },
      ],
      selectedStatus: null,
      purchaseDate: null,
      modalCurrentPage: null,
      selectedVendor: null,
      vendorList: [],
      priorityValue: null,
      priority: [],
      paymentMode: [],
      BehalfOfList: [],
      created_user_id: null,
      vhc_code: "",
      showPoOrderModal: false,
      showPoOrder1Modal: false,
      editPoParamsData: null,
      editPo1ParamsData: null,
      imagePopParamsData: null,
      expDetailsParamsData: null,
      showExpDetailsModal: false,
      showimageModal: false,
      selectedExpenseList: [],
      showTally: 0,
      totalPages: 0,
      excelurl: BASEURL,
      poOrders: [],
      User: "Select User",
      popupActive: false,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "Approve/Reject",
          field: "purchase_id",
          filter: false,
          width: 175,
          pinned: "left",
          sortable: false,
          cellStyle: { "text-align": "center" },
          cellRenderer: function(params) {
            if (params.data.purchase_status == 1) {
              return ' <span class="text-center table-edit-icon" style="background-color: green;" >  <i class="text-center material-icons">done</i> </span>';
            }
            if (params.data.purchase_status == 2) {
              return ' <span class="text-center table-edit-icon" style="background-color: red;" >  <i class="text-center material-icons">close</i> </span>';
            }
            if (
              params.data.pending_from_level &&
              params.data.pending_from_level == 1
            ) {
              return ' <span class="text-center table-edit-icon" style="background-color:  #ffde21;" >  <i class="text-center material-icons">edit</i> </span>';
            }
            if (
              params.data.pending_from_level &&
              params.data.pending_from_level == 2
            ) {
              return ' <span class="text-center table-edit-icon" style="background-color:  #4052d6;" >  <i class="text-center material-icons">edit</i> </span>';
            }
            if (
              params.data.pending_from_level &&
              params.data.pending_from_level == 3
            ) {
              return ' <span class="text-center table-edit-icon" style="background-color:  #ffa500;" >  <i class="text-center material-icons">edit</i> </span>';
            }
            if (
              params.data.pending_from_level &&
              params.data.pending_from_level == 4
            ) {
              return ' <span class="text-center table-edit-icon" style="background-color:  #ff2c2c;" >  <i class="text-center material-icons">edit</i> </span>';
            }
            return ' <span class="text-center table-edit-icon" >  <i class="text-center material-icons">edit</i> </span>';
          },
          // cellRendererFramework: editPoOrder,
          // cellRenderer: function(params) {
          //   return '<span class="text-center cursor-pointer" @click="popupActive=true" ><i class="text-center material-icons">edit</i></span>';
          // }
        },
        // {
        //   headerName: "Name",
        //   field: "po_name",
        //   width: 200,
        //   filter: true,
        // },
        {
          headerName: "Expense Status",
          field: "purchase_status",
          width: 165,
          filter: true,
          cellRenderer: function(params) {
            if (params.value == 0) {
              return `<div class="text-black font-bold">Pending<div>`;
            } else if (params.value == 1) {
              return `<div class="text-black font-bold">Approved<div>`;
            } else if (params.value == 2) {
              return `<div class="text-black font-bold">Rejected<div>`;
            }
          },
        },

        {
          headerName: "Vendor Name",
          field: "po_name",
          minWidth: 200,
          filter: true,
        },

        // {
        //   headerName: "Description",
        //   field: "purchase_description",
        //   filter: true,
        //   width: 275,
        // },
        {
          headerName: "Category",
          field: "parent_name",
          filter: true,
          width: 275,
        },
        {
          headerName: "Sub Category",
          field: "category_name",
          filter: true,
          width: 275,
        },

        {
          headerName: "Details",
          field: "exp_det",
          filter: true,
          width: 150,

          cellRenderer: function(params) {
            return '<span class="text-center table-edit-icon""> <i class="text-center material-icons">remove_red_eye</i>  </span>';
          },

          // cellRendererFramework: expenseDetails,
        },

        {
          headerName: "Amount",
          field: "total",
          filter: true,
          width: 150,
        },
        {
          headerName: "Tax/GST",
          field: "gst_amount",
          filter: true,
          width: 150,
        },
        {
          headerName: "TDS",
          field: "tds_amount",
          filter: true,
          width: 150,
        },
        {
          headerName: "Total Amount",
          field: "total_amount",
          filter: true,
          width: 150,
        },
        {
          headerName: "TDS Amount",
          field: "tds_amount",
          filter: true,
          width: 150,
          hide: true,
        },
        {
          headerName: "Paid Amount",
          field: "tally_paid_amount",
          filter: true,
          width: 150,
          hide: true,
        },
        // {
        //   headerName: "Currency",
        //   field: "currency",
        //   filter: true,
        //   width: 150
        // },
        // {
        //   headerName: "Mode of Pay",
        //   field: "payment_mode",
        //   filter: true,
        //   width: 145,
        //   cellRenderer: function(params) {
        //     return params.value == 1
        //       ? `Cash`
        //       : params.value == 2
        //       ? `Card`
        //       : `Bank`;
        //   }
        // },
        {
          headerName: "VHC Code",
          field: "VHC_code",
          filter: true,
          width: 250,
        },
        {
          headerName: "Created On",
          field: "created_date",
          minWidth: 200,
          filter: true,
        },
        // {
        //   headerName: "Tally Error",
        //   field: "export_tally_error",
        //   filter: true,
        //   width: 150,
        //   hide: true,
        // },
        {
          headerName: "Priority",
          field: "priority_name",
          filter: false,
          width: 110,
          cellRendererFramework: priority,
        },

        {
          headerName: "Multiple Images",
          field: "purchase_image",
          filter: true,
          width: 200,
          sortable: false,
          cellRenderer: function(params) {
            return params.data.hasOwnProperty("purchase_image") &&
              params.data.purchase_image != "-"
              ? '<span class="text-center table-edit-icon"> <i class="text-center material-icons">info</i> </span>'
              : '    <span class="text-center">No Images</span>';
          },
          // cellRendererFramework: imagePopUp,
        },
        {
          headerName: "Download",
          field: "showlogs",
          filter: true,
          sortable: false,
          width: 140,
          cellRendererFramework: downloadInvoiceVue,
        },
        // {
        //   headerName: "Verified Status",
        //   field: "is_verified",
        //   filter: true,
        //   cellRenderer: function(params) {
        //     return params.value == 1 ? `Verified` : "Not Verified";
        //   },
        // },
        {
          headerName: "City",
          field: "city_name",
          filter: true,
        },
        {
          headerName: "User",
          field: "user_name",
          minWidth: 200,
          maxWidth: 300,
          filter: true,
        },
        {
          headerName: "Log",
          field: "log",
          width: 165,
          filter: true,
          //  cellRendererFramework: editPoOrder1,
          cellRenderer: function(params) {
            return params.data.showlogs >= 1
              ? ' <span  class="text-center table-edit-icon" > <i class="text-center material-icons">remove_red_eye</i></span>'
              : '  <span class="text-center table-edit-icon greyme" v-else> <i class="text-center material-icons">remove_red_eye</i> </span>';
          },
        },
        {
          headerName: "Expense Type",
          field: "raised_from_var",
          filter: true,
          cellRenderer: function(params) {
            return params.value == 1
              ? `Against VAR`
              : params.value == 2
              ? `Against PCR`
              : "Normal Expense";
          },
        },
      ],
      projectID: null,
      organization_id: "",
      selectedTallyOption: "All",
      tallyType: null,
      isTally: "",
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },

    search(val) {
      if (val && val.length >= 2) {
        this.statusChanged();
      } else if (val == "") {
        this.statusChanged();
      }
    },
    limit() {
      this.statusChanged();
    },
  },
  computed: {
    filterForExcel() {
      let text = "";
      this.vhc_code ? (text += "&vhc_code=" + this.vhc_code) : "";
      this.created_user_id
        ? (text += "&created_user_id=" + this.created_user_id.user_id)
        : "";
      this.priorityValue
        ? (text += "&priority=" + this.priorityValue.priority_id)
        : "";
      this.selectedVendor
        ? (text += "&selectedVendor=" + this.selectedVendor.vendor_id)
        : "";
      return text;
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.purchaseOrderList(val);
          return val;
        } else return 1;
      },
    },
  },
  beforeMount() {
    this.organization_id = localStorage.getItem("OrganizationID");
    this.isTally = localStorage.getItem("isTally");

    const { projectID } = this.$route.params;
    this.projectID = projectID || null;
    eventBus.$on("refreshPOList", (e, modalCurrentPage) => {
      this.purchaseOrderList(modalCurrentPage);
    });
  },
  methods: {
    downloadSelected(selectedOption, id) {
      let URL = this.excelurl;
      if (selectedOption.value == "excel") {
        URL += `/v2/purchase/excel/download?organization_id=${
          this.organization_id
        }&token=${this.temp_token}&project_id=${
          this.projectID
        }&approver_id=0&search=${this.searchQuery}${this.filterForExcel}`;
        window.open(URL, "_blank");
        return;
      } else if (selectedOption.value == "pdf") {
        URL += `/v2/expenses/generateExpensesPDF?organization_id=${
          this.organization_id
        }&project_id=${this.projectID}&is_var=0`;

        fetch(URL, {
          headers: {
            "content-type": "application/Json",
            authorization: localStorage.getItem("access_token"),
            "x-access-token": localStorage.getItem("access_token"),
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            console.log(blob);
            var _url = window.URL.createObjectURL(blob);
            window.open(_url, "_blank").focus(); // window.open + focus
          })
          .catch((err) => {
            console.log(err);
          });
        return;
      } else return;
    },
    deletePurchase() {
      purchaseOrderService
        .deletePurchase(this.purchaseToDdelete.purchase_id)
        .then((response) => {
          if (!response.data.Status) {
            return this.$vs.notify({
              color: "warning",
              title: "Oops!",
              text: response.data.Message || "Error deleteing!",
            });
          }
          this.statusChanged();
          return this.$vs.notify({
            color: "danger",
            title: "Success",
            text: response.data.Message || "Deleted successfully.",
          });
        })
        .catch((error) =>
          this.$vs.notify({
            color: "warning",
            title: "Error",
            text: error.message || "Error deleteing!",
          })
        );

      this.cancelDeleting();
    },
    cancelDeleting() {
      this.confirmDeletePopup = false;
      this.purchaseToDdelete = {};
    },
    statusChanged() {
      this.$nextTick(() => {
        this.purchaseOrderList(1);
      });
    },
    onCellClicked(params) {
      console.log(params);
      if (params.colDef.field == "purchase_id") {
        this.showPoOrderModal = false;
        this.editPoParamsData = params;
        setTimeout(() => {
          this.showPoOrderModal = true;
        }, 300);
      }
      //
      if (params.colDef.field == "log" && params.data.showlogs >= 1) {
        this.purchaseDate = null;
        this.showPoOrder1Modal = false;
        let toTimestamp = Date.parse(params.data.created_date);
        this.purchaseDate = toTimestamp;
        this.editPo1ParamsData = params;
        setTimeout(() => {
          this.showPoOrder1Modal = true;
        }, 300);
      }

      if (
        params.colDef.field == "purchase_image" &&
        params.data.purchase_image &&
        params.data.purchase_image != "-"
      ) {
        this.showimageModal = false;
        this.imagePopParamsData = params;
        setTimeout(() => {
          this.showimageModal = true;
        }, 300);
      }
      if (params.colDef.field == "exp_det") {
        this.showExpDetailsModal = false;
        this.expDetailsParamsData = params;
        setTimeout(() => {
          this.showExpDetailsModal = true;
        }, 300);
      }

      if (params.colDef.field == "purchase_action") {
        this.confirmDeletePopup = true;
        this.purchaseToDdelete = params.data;
      }
    },
    syncVendor() {
      this.$vs.loading();
      let seletedExpenseString = "";
      if (this.selectedExpenseList.length > 0) {
        seletedExpenseString = this.selectedExpenseList.toString();
      } else {
        seletedExpenseString = "";
      }

      axios
        .get(
          `${BASEURL}/v2/tally/syncVoucherToTally?organization_id=${
            this.organization_id
          }&selectedExpenseList=${seletedExpenseString}`
        )
        .then((response) => {
          this.$vs.notify({
            // title: "Duplicate entries have not been imported.",
            title: "Updated!",
            text: "Sync Successfully",
            // time:8000,
            time: 2000,
            iconPack: "feather",
            icon: "check_box",
            color: "success",
          });
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
        });
    },
    filterData: function() {
      this.purchaseOrderList(1);
    },
    getVendorList: function() {
      ContractService.getContractVendorNew()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.vendorList = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    clearData: function() {
      this.vhc_code = "";
      this.created_user_id = null;
      this.priorityValue = null;
      this.selectedVendor = null;
      this.purchaseOrderList(1);
    },
    onSelectBehalf(selected) {},
    getMapList: function() {
      let payload = {
        project_id: this.projectID,
      };
      UserService.getMapList(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.BehalfOfList = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    purchaseOrderList: function(currentPage) {
      this.modalCurrentPage = currentPage;
      this.$vs.loading();
      let filters = {
        vhc_code: this.vhc_code && this.vhc_code != "" ? this.vhc_code : "",
        created_user_id: this.created_user_id
          ? this.created_user_id.user_id
          : null,
        priority: this.priorityValue ? this.priorityValue.priority_id : null,
        selectedVendor: this.selectedVendor
          ? this.selectedVendor.vendor_id
          : "",
      };
      this.poOrders = [];
      let payload = {
        limit: this.paginationPageSize,
        type: 0,
        page_no: currentPage,
        purchaseStatus: this.selectedStatus ? this.selectedStatus.value : null,
        sort_by: this.sortBy ? this.sortBy.value : null,
        search: this.search,
        limit: this.limit.value,
        ...filters,
      };
      if (this.selectedTallyOption != "All") {
        if (this.selectedTallyOption == "Approved") {
          payload.tallySync = "Approved";
        }
        if (this.selectedTallyOption == "Synced") {
          payload.tallySync = "Synced";
        }
        if (this.selectedTallyOption == "Unsynced") {
          payload.tallySync = "Unsynced";
        }
      }
      console.log("filters", payload);
      purchaseOrderService
        .getAllPurchaseOrderByProject(this.projectID, payload)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (!data.err || data.Status == true) {
            // if (data.data.length <= 0) {
            //   this.$vs.notify({
            //     title: "Warning!",
            //     text: "No data found!",
            //     iconPack: "feather",
            //     icon: "check_box",
            //     color: "warning",
            //   });
            // }
            this.poOrders = data.data;
            // console.log("Hey", this.poOrders)
            this.totalPages = data.pagination.last_page;
            // this.autoSizeAll();
          } else {
            this.poOrders = [];
          }
          if (data.hasOwnProperty("tally_isActive")) {
            this.showTally = data.tally_isActive;
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getMasterList: function() {
      MethodService.getAllMaster()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.priority = data.data.priority;
            this.paymentMode = data.data.payment_mode;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    autoSizeAll() {
      var allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function(column) {
        allColumnIds.push(column.colId);
      });
      this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
    },
    onSelectTallySyncOption: function(syncType) {
      this.selectedTallyOption = syncType;

      if (syncType == "Unsynced") {
        this.columnDefs = [
          {
            headerName: "",
            field: "",
            filter: false,
            pinned: "left",
            width: 80,
            suppressSizeToFit: true,
            cellRendererFramework: multiSelectExpenses,
          },
          {
            headerName: "Approve/Reject",
            field: "purchase_id",
            filter: false,
            width: 175,
            pinned: "left",
            sortable: false,
            cellStyle: { "text-align": "center" },
            cellRenderer: function(params) {
              if (params.data.purchase_status == 1) {
                return ' <span class="text-center table-edit-icon" style="background-color: green;" >  <i class="text-center material-icons">done</i> </span>';
              }
              if (params.data.purchase_status == 2) {
                return ' <span class="text-center table-edit-icon" style="background-color: red;" >  <i class="text-center material-icons">close</i> </span>';
              }
              if (
                params.data.pending_from_level &&
                params.data.pending_from_level == 1
              ) {
                return ' <span class="text-center table-edit-icon" style="background-color:  #ffde21;" >  <i class="text-center material-icons">edit</i> </span>';
              }
              if (
                params.data.pending_from_level &&
                params.data.pending_from_level == 2
              ) {
                return ' <span class="text-center table-edit-icon" style="background-color:  #4052d6;" >  <i class="text-center material-icons">edit</i> </span>';
              }
              if (
                params.data.pending_from_level &&
                params.data.pending_from_level == 3
              ) {
                return ' <span class="text-center table-edit-icon" style="background-color:  #ffa500;" >  <i class="text-center material-icons">edit</i> </span>';
              }
              if (
                params.data.pending_from_level &&
                params.data.pending_from_level == 4
              ) {
                return ' <span class="text-center table-edit-icon" style="background-color:  #ff2c2c;" >  <i class="text-center material-icons">edit</i> </span>';
              }
              return ' <span class="text-center table-edit-icon" >  <i class="text-center material-icons">edit</i> </span>';
            },
            //cellRendererFramework: editPoOrder,
            // cellRenderer: function(params) {
            //   return '<span class="text-center cursor-pointer" @click="popupActive=true" ><i class="text-center material-icons">edit</i></span>';
            // }
          },
          {
            headerName: "Expense Status",
            field: "purchase_status",
            width: 165,
            filter: true,
            cellRenderer: function(params) {
              if (params.value == 0) {
                return `<div class="text-warning font-bold">Pending<div>`;
              } else if (params.value == 1) {
                return `<div class="text-success font-bold">Approved<div>`;
              } else if (params.value == 2) {
                return `<div class="text-danger font-bold">Rejected<div>`;
              }
            },
          },
          {
            headerName: "Unsync Reason",
            field: "unsync_reason",
            minWidth: 200,
            filter: true,
          },
          {
            headerName: "Name",
            field: "po_name",
            minWidth: 200,
            filter: true,
          },
          {
            headerName: "User",
            field: "user_name",
            minWidth: 200,
            maxWidth: 300,
            filter: true,
          },
          // {
          //   headerName: "Description",
          //   field: "purchase_description",
          //   filter: true,
          //   width: 275,
          // },
          // {
          //   headerName: "Category",
          //   field: "category_name",
          //   filter: true,
          //   width: 275,
          // },
          // {
          //   headerName: "Sub Category",
          //   field: "sub_category_name",
          //   filter: true,
          //   width: 275,
          // },
          {
            headerName: "City",
            field: "city_name",
            filter: true,
          },
          {
            headerName: "Details",
            field: "exp_det",
            filter: true,
            width: 150,
            cellRenderer: function(params) {
              return '<span class="text-center table-edit-icon""> <i class="text-center material-icons">remove_red_eye</i>  </span>';
            },
            // cellRendererFramework: expenseDetails,
          },

          {
            headerName: "Amount",
            field: "total_amount",
            filter: true,
            width: 150,
          },
          {
            headerName: "TDS Amount",
            field: "tds_amount",
            filter: true,
            width: 150,
            hide: true,
          },
          {
            headerName: "Paid Amount",
            field: "tally_paid_amount",
            filter: true,
            width: 150,
            hide: true,
          },
          // {
          //   headerName: "Currency",
          //   field: "currency",
          //   filter: true,
          //   width: 150
          // },
          // {
          //   headerName: "Mode of Pay",
          //   field: "payment_mode",
          //   filter: true,
          //   width: 145,
          //   cellRenderer: function(params) {
          //     return params.value == 1
          //       ? `Cash`
          //       : params.value == 2
          //       ? `Card`
          //       : `Bank`;
          //   }
          // },
          {
            headerName: "VHC Code",
            field: "VHC_code",
            filter: true,
            width: 150,
          },
          {
            headerName: "Expense Type",
            field: "raised_from_var",
            filter: true,
            cellRenderer: function(params) {
              return params.value == 1
                ? `Against VAR`
                : params.value == 2
                ? `Against PCR`
                : "Normal Expense";
            },
            //   pinned: "left"
          },
          {
            headerName: "Created On",
            field: "created_date",
            minWidth: 200,
            filter: true,
          },
          {
            headerName: "Tally Error",
            field: "export_tally_error",
            filter: true,
            width: 150,
            hide: false,
          },
          {
            headerName: "Priority",
            field: "priority_name",
            filter: false,
            width: 110,
            cellRendererFramework: priority,
          },

          {
            headerName: "Multiple Images",
            field: "purchase_image",
            filter: true,
            width: 200,
            sortable: false,
            cellRenderer: function(params) {
              return params.data.hasOwnProperty("purchase_image") &&
                params.data.purchase_image != "-"
                ? '<span class="text-center table-edit-icon"> <i class="text-center material-icons">info</i> </span>'
                : '    <span class="text-center">No Images</span>';
            },
            //  cellRendererFramework: imagePopUp,
          },
          {
            headerName: "Download",
            field: "showlogs",
            filter: true,
            sortable: false,
            width: 140,
            cellRendererFramework: downloadInvoiceVue,
          },
          // {
          //   headerName: "Verified Status",
          //   field: "is_verified",
          //   filter: true,
          //   cellRenderer: function(params) {
          //     return params.value == 1 ? `Verified` : "Not Verified";
          //   },
          //   //   pinned: "left"
          // },
        ];
      } else if (syncType == "Synced") {
        this.columnDefs = [
          {
            headerName: "Approve/Reject",
            field: "purchase_id",
            filter: false,
            width: 175,
            pinned: "left",
            sortable: false,
            cellStyle: { "text-align": "center" },
            cellRenderer: function(params) {
              if (params.data.purchase_status == 1) {
                return ' <span class="text-center table-edit-icon" style="background-color: green;" >  <i class="text-center material-icons">done</i> </span>';
              }
              if (params.data.purchase_status == 2) {
                return ' <span class="text-center table-edit-icon" style="background-color: red;" >  <i class="text-center material-icons">close</i> </span>';
              }
              if (
                params.data.pending_from_level &&
                params.data.pending_from_level == 1
              ) {
                return ' <span class="text-center table-edit-icon" style="background-color:  #ffde21;" >  <i class="text-center material-icons">edit</i> </span>';
              }
              if (
                params.data.pending_from_level &&
                params.data.pending_from_level == 2
              ) {
                return ' <span class="text-center table-edit-icon" style="background-color:  #4052d6;" >  <i class="text-center material-icons">edit</i> </span>';
              }
              if (
                params.data.pending_from_level &&
                params.data.pending_from_level == 3
              ) {
                return ' <span class="text-center table-edit-icon" style="background-color:  #ffa500;" >  <i class="text-center material-icons">edit</i> </span>';
              }
              if (
                params.data.pending_from_level &&
                params.data.pending_from_level == 4
              ) {
                return ' <span class="text-center table-edit-icon" style="background-color:  #ff2c2c;" >  <i class="text-center material-icons">edit</i> </span>';
              }
              return ' <span class="text-center table-edit-icon" >  <i class="text-center material-icons">edit</i> </span>';
            },
            // cellRendererFramework: editPoOrder,
            // cellRenderer: function(params) {
            //   return '<span class="text-center cursor-pointer" @click="popupActive=true" ><i class="text-center material-icons">edit</i></span>';
            // }
          },
          // {
          //   headerName: "Name",
          //   field: "po_name",
          //   width: 200,
          //   filter: true,
          // },
          {
            headerName: "Expense Status",
            field: "purchase_status",
            width: 165,
            filter: true,
            cellRenderer: function(params) {
              if (params.value == 0) {
                return `<div class="text-warning font-bold">Pending<div>`;
              } else if (params.value == 1) {
                return `<div class="text-success font-bold">Approved<div>`;
              } else if (params.value == 2) {
                return `<div class="text-danger font-bold">Rejected<div>`;
              }
            },
          },
          {
            headerName: "Name",
            field: "po_name",
            minWidth: 200,
            filter: true,
          },
          {
            headerName: "User",
            field: "user_name",
            minWidth: 200,
            maxWidth: 300,
            filter: true,
          },
          // {
          //   headerName: "Description",
          //   field: "purchase_description",
          //   filter: true,
          //   width: 275,
          // },
          // {
          //   headerName: "Category",
          //   field: "category_name",
          //   filter: true,
          //   width: 275,
          // },
          // {
          //   headerName: "Sub Category",
          //   field: "sub_category_name",
          //   filter: true,
          //   width: 275,
          // },

          {
            headerName: "City",
            field: "city_name",
            filter: true,
          },
          {
            headerName: "Details",
            field: "exp_det",
            filter: true,
            width: 150,
            cellRenderer: function(params) {
              return '<span class="text-center table-edit-icon""> <i class="text-center material-icons">remove_red_eye</i>  </span>';
            },
            //cellRendererFramework: expenseDetails,
          },

          {
            headerName: "Payment Due Date",
            field: "payment_due_date",
            filter: false,
            width: 255,
          },
          {
            headerName: "Amount",
            field: "total_amount",
            filter: true,
            width: 150,
          },
          {
            headerName: "TDS Amount",
            field: "tds_amount",
            filter: true,
            width: 150,
            hide: false,
            cellRenderer: function(params) {
              return params.data.raised_from_var == 2
                ? "NA"
                : params.data.tds_amount;
            },
            // cellRenderer: function (data) {
            //   return params.data.is_var;
            // },
          },
          {
            headerName: "Paid Amount",
            field: "tally_paid_amount",
            filter: true,
            width: 150,
            hide: false,
            cellRenderer: function(params) {
              return params.data.raised_from_var == 2
                ? "NA"
                : params.data.tally_paid_amount;
            },
          },
          // {
          //   headerName: "Currency",
          //   field: "currency",
          //   filter: true,
          //   width: 150
          // },
          // {
          //   headerName: "Mode of Pay",
          //   field: "payment_mode",
          //   filter: true,
          //   width: 145,
          //   cellRenderer: function(params) {
          //     return params.value == 1
          //       ? `Cash`
          //       : params.value == 2
          //       ? `Card`
          //       : `Bank`;
          //   }
          // },
          {
            headerName: "VHC Code",
            field: "VHC_code",
            filter: true,
            width: 150,
          },
          {
            headerName: "Expense Type",
            field: "raised_from_var",
            filter: true,
            cellRenderer: function(params) {
              return params.value == 1
                ? `Against VAR`
                : params.value == 2
                ? `Against PCR`
                : "Normal Expense";
            },
            //   pinned: "left"
          },
          {
            headerName: "Created On",
            field: "created_date",
            minWidth: 200,
            filter: true,
          },
          {
            headerName: "Tally Error",
            field: "export_tally_error",
            filter: true,
            width: 150,
            hide: true,
          },
          {
            headerName: "Priority",
            field: "priority_name",
            filter: false,
            width: 110,
            cellRendererFramework: priority,
          },

          {
            headerName: "Multiple Images",
            field: "purchase_image",
            filter: true,
            width: 200,
            sortable: false,
            cellRenderer: function(params) {
              return params.data.hasOwnProperty("purchase_image") &&
                params.data.purchase_image != "-"
                ? '<span class="text-center table-edit-icon"> <i class="text-center material-icons">info</i> </span>'
                : '    <span class="text-center">No Images</span>';
            },
            // cellRendererFramework: imagePopUp,
          },
          {
            headerName: "Download",
            field: "showlogs",
            filter: true,
            sortable: false,
            width: 140,
            cellRendererFramework: downloadInvoiceVue,
          },
          // {
          //   headerName: "Verified Status",
          //   field: "is_verified",
          //   filter: true,
          //   cellRenderer: function(params) {
          //     return params.value == 1 ? `Verified` : "Not Verified";
          //   },
          //   //   pinned: "left"
          // },
        ];
      } else {
        this.columnDefs = [
          {
            headerName: "Approve/Reject",
            field: "purchase_id",
            filter: false,
            width: 175,
            pinned: "left",
            sortable: false,
            cellStyle: { "text-align": "center" },
            //cellRendererFramework: editPoOrder,
            cellRenderer: function(params) {
              if (params.data.purchase_status == 1) {
                return ' <span class="text-center table-edit-icon" style="background-color: green;" >  <i class="text-center material-icons">done</i> </span>';
              }
              if (params.data.purchase_status == 2) {
                return ' <span class="text-center table-edit-icon" style="background-color: red;" >  <i class="text-center material-icons">close</i> </span>';
              }
              if (
                params.data.pending_from_level &&
                params.data.pending_from_level == 1
              ) {
                return ' <span class="text-center table-edit-icon" style="background-color:  #ffde21;" >  <i class="text-center material-icons">edit</i> </span>';
              }
              if (
                params.data.pending_from_level &&
                params.data.pending_from_level == 2
              ) {
                return ' <span class="text-center table-edit-icon" style="background-color:  #4052d6;" >  <i class="text-center material-icons">edit</i> </span>';
              }
              if (
                params.data.pending_from_level &&
                params.data.pending_from_level == 3
              ) {
                return ' <span class="text-center table-edit-icon" style="background-color:  #ffa500;" >  <i class="text-center material-icons">edit</i> </span>';
              }
              if (
                params.data.pending_from_level &&
                params.data.pending_from_level == 4
              ) {
                return ' <span class="text-center table-edit-icon" style="background-color:  #ff2c2c;" >  <i class="text-center material-icons">edit</i> </span>';
              }
              return ' <span class="text-center table-edit-icon" >  <i class="text-center material-icons">edit</i> </span>';
            },
            // cellRenderer: function(params) {
            //   return '<span class="text-center cursor-pointer" @click="popupActive=true" ><i class="text-center material-icons">edit</i></span>';
            // }
          },
          // {
          //   headerName: "Name",
          //   field: "po_name",
          //   width: 200,
          //   filter: true,
          // },
          {
            headerName: "Expense Status",
            field: "purchase_status",
            width: 165,
            filter: true,
            cellRenderer: function(params) {
              if (params.value == 0) {
                return `<div class="text-warning font-bold">Pending<div>`;
              } else if (params.value == 1) {
                return `<div class="text-success font-bold">Approved<div>`;
              } else if (params.value == 2) {
                return `<div class="text-danger font-bold">Rejected<div>`;
              }
            },
          },
          {
            headerName: "Name",
            field: "po_name",
            minWidth: 200,
            filter: true,
          },
          {
            headerName: "User",
            field: "user_name",
            minWidth: 200,
            maxWidth: 300,
            filter: true,
          },
          // {
          //   headerName: "Description",
          //   field: "purchase_description",
          //   filter: true,
          //   width: 275,
          // },
          // {
          //   headerName: "Category",
          //   field: "category_name",
          //   filter: true,
          //   width: 275,
          // },
          // {
          //   headerName: "Sub Category",
          //   field: "sub_category_name",
          //   filter: true,
          //   width: 275,
          // },

          {
            headerName: "City",
            field: "city_name",
            filter: true,
          },
          {
            headerName: "Details",
            field: "exp_det",
            filter: true,
            width: 150,
            cellRenderer: function(params) {
              return '<span class="text-center table-edit-icon""> <i class="text-center material-icons">remove_red_eye</i>  </span>';
            },
            // cellRendererFramework: expenseDetails,
          },

          {
            headerName: "Amount",
            field: "total_amount",
            filter: true,
            width: 150,
          },
          {
            headerName: "TDS Amount",
            field: "tds_amount",
            filter: true,
            width: 150,
            hide: true,
          },
          {
            headerName: "Paid Amount",
            field: "tally_paid_amount",
            filter: true,
            width: 150,
            hide: true,
          },
          // {
          //   headerName: "Currency",
          //   field: "currency",
          //   filter: true,
          //   width: 150
          // },
          // {
          //   headerName: "Mode of Pay",
          //   field: "payment_mode",
          //   filter: true,
          //   width: 145,
          //   cellRenderer: function(params) {
          //     return params.value == 1
          //       ? `Cash`
          //       : params.value == 2
          //       ? `Card`
          //       : `Bank`;
          //   }
          // },
          {
            headerName: "VHC Code",
            field: "VHC_code",
            filter: true,
            width: 150,
          },
          {
            headerName: "Expense Type",
            field: "raised_from_var",
            filter: true,
            cellRenderer: function(params) {
              return params.value == 1
                ? `Against VAR`
                : params.value == 2
                ? `Against PCR`
                : "Normal Expense";
            },
            //   pinned: "left"
          },
          {
            headerName: "Created On",
            field: "created_date",
            minWidth: 200,
            filter: true,
          },
          {
            headerName: "Tally Error",
            field: "export_tally_error",
            filter: true,
            width: 150,
            hide: true,
          },
          {
            headerName: "Priority",
            field: "priority_name",
            filter: false,
            width: 110,
            cellRendererFramework: priority,
          },

          {
            headerName: "Multiple Images",
            field: "purchase_image",
            filter: true,
            width: 200,
            sortable: false,
            cellRenderer: function(params) {
              return params.data.hasOwnProperty("purchase_image") &&
                params.data.purchase_image != "-"
                ? '<span class="text-center table-edit-icon"> <i class="text-center material-icons">info</i> </span>'
                : '    <span class="text-center">No Images</span>';
            },
            // cellRendererFramework: imagePopUp,
          },
          {
            headerName: "Download",
            field: "showlogs",
            filter: true,
            sortable: false,
            width: 140,
            cellRendererFramework: downloadInvoiceVue,
          },
          // {
          //   headerName: "Verified Status",
          //   field: "is_verified",
          //   filter: true,
          //   cellRenderer: function(params) {
          //     return params.value == 1 ? `Verified` : "Not Verified";
          //   },
          //   //   pinned: "left"
          // },
        ];
      }

      let payload = {
        limit: this.paginationPageSize,
        type: 0,
        page_no: this.currentPage,
        tallySync: syncType,
      };
      purchaseOrderService
        .getAllPurchaseOrderByProject(this.projectID, payload)
        .then((response) => {
          const { data } = response;
          if (!data.err || data.Status == true) {
            this.poOrders = data.data;
            this.totalPages = data.pagination.last_page;
            // this.autoSizeAll();
          } else {
          }

          if (data.hasOwnProperty("tally_isActive")) {
            this.showTally = data.tally_isActive;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    eventBus.$on("selectedExpense", (e) => {
      let arrIndex = this.selectedExpenseList.indexOf(e);
      if (arrIndex >= 0) {
        this.selectedExpenseList.splice(arrIndex, 1);
      } else {
        this.selectedExpenseList.push(e);
      }
      // this.getWorkTypeList(this.currentPage);
    });
    this.purchaseOrderList(this.currentPage);
    this.getMapList();
    this.getMasterList();
    this.getVendorList();
    this.gridApi = this.gridOptions.api;
    // if (
    //   this.permissionPageAccessArry.includes(
    //     this.PermissionVariables.DeleteExpenses
    //   ) ||
    //   localStorage.getItem("UserRole").toLowerCase() == "admin"
    // ) {
    //   this.columnDefs.push({
    //     headerName: "Action",
    //     field: "purchase_action",
    //     filter: true,
    //     cellRenderer: function(params) {
    //       return params.data.purchase_status == 2
    //         ? '<span class="text-center table-edit-icon"> <i class="text-center material-icons">delete</i> </span>'
    //         : "N/A";
    //     },
    //   });
    // }
  },
};
</script>

<style scoped>
.po-user-card {
  width: 130px;
  height: auto;
  padding: 5px 5px 0px 5px;
  margin-bottom: 1rem;
  border-radius: 8px;
  border: 1px solid #e5e8eb;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
</style>
