<template>
  <div>
    <template v-if="params.data.purchase_status == 1">
      <a
        target="_blank"
        :href="
          params.data.purchase_status == 1
            ? URL_BASE +
              '/v2/purchase/download/' +
              params.data.purchase_id +
              '?organization_id=' +
              organization_id +
              '&token=' +
              temp_token
            : ''
        "
        @click="params.data.purchase_status == 1 ? '' : 'return false'"
      >
        <span class="text-center table-edit-icon" @click="downloadPo()">
          <i class="text-center material-icons">get_app</i>
        </span>
      </a>
    </template>
    <template v-else>
      <span
        class="text-center table-edit-icon view-icon-disabled"
        @click="downloadPo()"
      >
        <i class="text-center material-icons">get_app</i>
      </span>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import projectService from "@/services/projectService.js";
import { BASEURL } from "@/config/index.js";
export default Vue.extend({
  data() {
    return {
      params: null,
      purchase_id: "",
      URL_BASE: BASEURL,
    };
  },
  computed: {
    organization_id() {
      return window.localStorage.getItem("OrganizationID");
    },
  },

  beforeMount: function () {
    this.purchase_id = this.params.value;

    // console.log(this.params, "jj", this.params.value);
  },
  methods: {
    downloadPo: function () {
      // projectService
      //   .getPOPdf(this.purchase_id)
      //   .then(response => {
      //     const { data } = response;
      //     //   this.pr
      //     console.log("data :", data);
      //     if (!data.error) {
      //       console.log("objecww");
      //     } else {
      //     }
      //   })
      //   .catch(error => {
      //     console.log("error:", error);
      //   });
    },
  },
});
</script>

<style></style>
