<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <div class="vx-col w-full">
        <router-link to="/apps/projects">
          <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
        </router-link>
      </div>
      <div class="vx-col w-full">
        <vx-card class="mb-5">
          <!-- <div class="col-12">
            <table class="project-details">
              <tr>
                <td>Project Name:</td>
                <td>{{projectDetails.project_name}}</td>
              </tr>
              <tr>
                <td>Project Details:</td>
                <td>{{projectDetails.project_details}}</td>
              </tr>
              <tr>
                <td>Company Name:</td>
                <td>{{projectDetails.company_name}}</td>
              </tr>
            </table>
          </div>-->
          <!--
          <div class="row">
            <div class="col-md-3 col-lg-2 text-right">Project Name:</div>
            <div class="col-md-9 col-lg-10">{{projectDetails.project_name}}</div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-2 text-right">Project Details:</div>
            <div class="col-md-9 col-lg-10">{{projectDetails.project_details}}</div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-2 text-right">Company Name:</div>
            <div class="col-md-9 col-lg-10">{{projectDetails.company_name}}</div>
          </div>-->

          <div class="project-details">
            Project Name:
            <span class="ml-3">{{ projectDetails.project_name || "-" }}</span>
          </div>

          <div class="project-details">
            Project Details:
            <span class="ml-3">{{
              projectDetails.project_details || "-"
            }}</span>
          </div>

          <div class="project-details">
            Company Name:
            <span class="ml-3">{{ projectDetails.company_name || "-" }}</span>
          </div>
        </vx-card>
      </div>

      <div class="vx-col w-full">
        <vs-tabs>
          <vs-tab label="Expenses" icon-pack="feather" icon="icon-box">
            <div>
              <expenss-list></expenss-list>
            </div>
          </vs-tab>
          <vs-tab
            label="Petty Cash Request List"
            icon-pack="feather"
            icon="icon-box"
            v-if="isAdvanced"
          >
            <div class="tab-text">
              <div>
                <cashRequestList></cashRequestList>
              </div>
            </div>
          </vs-tab>
          <vs-tab
            label="Vendor Advance Request List"
            icon-pack="feather"
            icon="icon-box"
          >
            <div>
              <vendor-advance-request-list></vendor-advance-request-list>
            </div>
          </vs-tab>
          <vs-tab label="Credit Note List" icon-pack="feather" icon="icon-box">
            <div>
              <credit-note-list-new></credit-note-list-new>
            </div>
          </vs-tab>

          <!-- <vs-tab label="Expenses" icon-pack="feather" icon="icon-box">
            <ExpenseList :project_id="projectId" />
          </vs-tab>
          <vs-tab
            label="Petty Cash Request"
            icon-pack="feather"
            icon="icon-box"
          >
            <ExpenseList
              :is_var="3"
              listHeading="Petty Cash Request List"
              :project_id="projectId"
            />
          </vs-tab>
          <vs-tab
            label="Vendor Advance Request"
            icon-pack="feather"
            icon="icon-box"
          >
            <ExpenseList
              :is_var="1"
              listHeading="Vendor Advance Request List"
              :project_id="projectId"
            />
          </vs-tab>
          <vs-tab label="Credit Note" icon-pack="feather" icon="icon-box">
            <ExpenseList
              :is_var="2"
              listHeading="Credit Note List"
              :project_id="projectId"
            />
          </vs-tab> -->

          <vs-tab
            v-if="show_po == true"
            label="Purchase Order List"
            icon-pack="feather"
            icon="icon-box"
          >
            <div class="tab-text">
              <div>
                <purchase-order-list></purchase-order-list>
              </div>
            </div>
          </vs-tab>

          <!-- <vs-tab
            label="Credit Note List"
            icon-pack="feather"
            icon="icon-box"
            v-if="isCreditNote"
          >
           
              <div>
                <creditNoteList></creditNoteList>
              </div>
            </div>
          </vs-tab> -->
          <!-- CHALLAN LIST -->
          <!-- <vs-tab label="Challan List" icon-pack="feather" icon="icon-box">
          <div>
            <challan-list></challan-list>
          </div>
          </vs-tab>-->
        </vs-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import cashRequestList from "@/views/apps/projects/cashRequestListPending.vue";
import purchaseOrderList from "@/views/apps/projects/purchaseOrderList.vue";
import challanList from "@/views/apps/projects/ChallanList.vue";

import expenssList from "@/views/apps/projects/expenssList.vue";
import vendorAdvanceRequestList from "@/views/apps/projects/vendorAdvanceRequestList.vue";
import creditNoteListNew from "@/views/apps/projects/creditNoteListNew.vue";
import creditNoteList from "@/views/apps/projects/creditNoteList.vue";

import projectService from "@/services/projectService.js";
import ExpenseList from "./ExpenseList.vue";

export default {
  data() {
    return {
      show_po: window.localStorage.getItem("isPurchaseOrder"),
      projectId: null,
      isActive: false,
      projectDetails: {},
      analyticsData: "",
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      show: false,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot",
      ],
    };
  },
  methods: {
    myFilter: function() {
      this.isActive = !this.isActive;
    },
    getSingleProject: function() {
      projectService
        .getProject(this.projectId)
        .then((response) => {
          const { data } = response;
          this.projectDetails = data.data;
          if (data.Status == true || data.Status == "true") {
          } else {
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
  },

  components: {
    ExpenseList,
    purchaseOrderList,
    cashRequestList,
    challanList,
    expenssList,
    creditNoteList,
    vendorAdvanceRequestList,
    creditNoteListNew,
  },
  computed: {
    isAdvanced() {
      return window.localStorage.getItem("isAdvanced") == 1;
    },
    isCreditNote() {
      return window.localStorage.getItem("isCreditNote") == 1;
    },
  },
  mounted() {
    const { projectID } = this.$route.params;
    this.projectId = Number(projectID);
    this.getSingleProject();
  },
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
.con-vs-tabs .con-ul-tabs,
.vs-tabs--ul {
  background-color: #fff !important;
}

.theme-dark .con-vs-tabs .theme-dark .con-ul-tabs,
.theme-dark .vs-tabs--ul {
  background-color: #10163a !important;
  -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
}

.theme-dark .con-vs-tabs .theme-dark .con-ul-tabs,
.theme-dark .vs-tabs--ul span {
  color: #c2c6dc;
  font-weight: bold;
}
</style>
