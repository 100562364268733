<template>
  <div class="">
    <div class="flex flex-wrap items-center justify-between">
      <div class="w-full sm:w-1/2 mb-2">
        <p class="text-xs">Expense Name</p>
        <h6 class="text-xl font-semibold">{{ data.po_name }}</h6>
      </div>
      <div class="w-full sm:w-1/2 mb-2">
        <p class="text-xs">Invoice Number:</p>
        <h6 class="text-xl font-semibold">{{ data.invoice_number || "-" }}</h6>
      </div>
      <div class="w-full sm:w-1/2 mb-2">
        <p class="text-xs">Currency</p>
        <h6 class="text-xl font-semibold">{{ data.currency }}</h6>
      </div>
      <div class="w-full sm:w-1/2 mb-2">
        <p class="text-xs">Payment Mode</p>
        <h6 class="text-xl font-semibold">{{ data.payment_mode_name }}</h6>
      </div>
      <div class="w-full sm:w-1/2 mb-2">
        <p class="text-xs">Invoice Date</p>
        <h6 class="text-xl font-semibold">{{ data.invoice_date || "-" }}</h6>
      </div>
      <div class="w-full sm:w-1/2 mb-2">
        <p class="text-xs">GST Name</p>
        <h6 class="text-xl font-semibold">{{ data.gst_name || "-" }}</h6>
      </div>
      <div class="w-full sm:w-1/2 mb-2">
        <p class="text-xs">TAX Amount</p>
        <h6 class="text-xl font-semibold">{{ data.gst_amount || "-" }}</h6>
      </div>
      <div class="w-full sm:w-1/2 mb-2">
        <p class="text-xs">Base Amount</p>
        <h6 class="text-xl font-semibold">{{ data.total || "-" }}</h6>
      </div>
      <div class="w-full sm:w-1/2 mb-2">
        <p class="text-xs">Payment Due Date</p>
        <h6 class="text-xl font-semibold">
          {{ data.payment_due_date || "-" }}
        </h6>
      </div>
      <div class="w-full sm:w-1/2 mb-2">
        <p class="text-xs">Set Name</p>
        <h6 class="text-xl font-semibold">{{ data.location_name || "-" }}</h6>
      </div>
      <div class="w-full sm:w-1/2 mb-2">
        <p class="text-xs">Category</p>
        <h6 class="text-xl font-semibold">{{ data.parent_name || "-" }}</h6>
      </div>
      <div class="w-full sm:w-1/2 mb-2">
        <p class="text-xs">Sub Category</p>
        <h6 class="text-xl font-semibold">{{ data.category_name || "-" }}</h6>
      </div>
      <div class="w-full sm:w-1/2 mb-2" v-if="data.is_var == '2'">
        <p class="text-xs">Expense Invoice No.:</p>
        <h6 class="text-xl font-semibold">
          {{ data.expense_invoice_number || "-" }}
        </h6>
      </div>
      <div class="w-full sm:w-1/2 mb-2" v-if="data.is_var == '2'">
        <p class="text-xs">Expense Payment Due Date:</p>
        <h6 class="text-xl font-semibold">
          {{ data.exp_payment_due_date || "-" }}
        </h6>
      </div>
      <div class="w-full sm:w-1/2 mb-2" v-if="data.is_var == '2'">
        <p class="text-xs">Expense Invoice Amount:</p>
        <h6 class="text-xl font-semibold">{{ data.expense_amount || "-" }}</h6>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
.vs-popup--content {
  padding-top: 0 !important;
}
.heading {
  font-weight: bolder;
  font-size: 24px;
  font-variant: all-small-caps;
  color: rgba(var(--vs-primary), 1);
}
</style>
