<template>
  <div>
    <div v-if="hideIcon == false">
      <span
        class="text-center table-edit-icon"
        @click="showModal"
        v-if="params.data.showlogs >= 1"
      >
        <i class="text-center material-icons">remove_red_eye</i>
      </span>
      <span class="text-center table-edit-icon greyme" v-else>
        <i class="text-center material-icons">remove_red_eye</i>
      </span>
    </div>

    <!-- POPUP -->
    <div>
      <vs-popup
        style="z-index: 999999 !important"
        :id="'popmodal' + purchase_id"
        title="Expense"
        :active.sync="popupActiveImages"
      >
        <!-- <swiper :options="swiperOption" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl">
          <swiper-slide :key="imageKey" v-for="(image,imageKey) in images">
            <div class="swiper-zoom-container">
              <img class="responsive text-center" :src="image+'?token='+tempToken" alt="banner" />
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
          <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
        </swiper>-->

        <!-- Swiper -->
        <swiper :options="swiperOption">
          <swiper-slide
            class="swiper-slide"
            :key="imageKey"
            v-for="(image, imageKey) in images"
          >
            <div
              class="swiper-zoom-container"
              v-if="extensions.indexOf(image.split('.').slice(-1)[0]) >= 0"
            >
              <img
                style="height: 60vh; width: auto"
                :src="image + '?token=' + tempToken"
                alt="banner"
              />
            </div>

            <!-- <div class="swiper-zoom-container" v-else-if="false">
              <vue-pdf-viewer height="500px" :url="image">
                  </vue-pdf-viewer>
            </div>-->
            <div class="row col-12" v-else>
              <vs-button
                class="px-4 text-center"
                @click.native="downloadPdf(image, tempToken)"
                style="margin: auto"
              >
                <span class="d-inline-flex pr-5 text-white"
                  >Click here to preview</span
                >
                <img
                  src="@/assets/images/pdf-icon.png"
                  v-if="image.split('.').slice(-1)[0] == 'pdf'"
                  class="d-inline-flex"
                  height="40"
                  width="40"
                />
                <img
                  src="@/assets/images/excelLogo.png"
                  v-else
                  class="d-inline-flex"
                  height="40"
                  width="40"
                />
              </vs-button>
            </div>
            <div
              class="mx-auto text-center my-2"
              v-if="extensions.indexOf(image.split('.').slice(-1)[0]) >= 0"
            >
              <button
                @click="downloadPdf(image)"
                class="vs-component vs-button vs-button-primary vs-button-filled"
              >
                Download
              </button>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </vs-popup>

      <vs-popup
        class="popmodalViewR"
        :id="'popmodalViewR' + params.data.purchase_id"
        title="Expense Logs"
        :active.sync="popupActive0"
      >
        <div
          class="vx-col w-full sb-border"
          v-for="(data, index) in expenses_datas"
          :key="index"
        >
          <div class="mx-0 row mb-4">
            <div class="col-6 mx-auto text-center">
              <div>
                <p class="text-muted small mb-1">Expenses Created By:</p>
                <div class>
                  <h5>{{ expenses_create_by }}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-0 row mb-4">
            <div class="col-6">
              <p class="text-muted small mb-1">Action:</p>
              <div>
                <h5>{{ data.actionDescription }}</h5>
              </div>
            </div>
            <div class="col-6">
              <p class="text-muted small mb-1">Date:</p>
              <div>
                <h5>{{ data.actionDate }}</h5>
              </div>
            </div>
          </div>
          <div class="mx-0 row mb-4">
            <div class="col-4">
              <div>
                <p class="text-muted small mb-1">VHC Code:</p>
                <div class>
                  <h5>{{ data.VHC_code }}</h5>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div>
                <p class="text-muted small mb-1">Category:</p>
                <div class>
                  <h5>{{ data.category }}</h5>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div>
                <p class="text-muted small mb-1">Sub Category:</p>
                <div class>
                  <h5>{{ data.sub_category }}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-0 row mb-4">
            <div class="col-4">
              <div>
                <p class="text-muted small mb-1">Invoice Number:</p>
                <div class>
                  <h5>{{ data.invoice_number }}</h5>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div>
                <p class="text-muted small mb-1">Invoice Date:</p>
                <div class>
                  <h5>{{ data.invoice_date }}</h5>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div>
                <p class="text-muted small mb-1">Payment Due Date:</p>
                <div class>
                  <h5>{{ data.payment_due_date }}</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="mx-0 row mb-4">
            <div class="col-4">
              <div>
                <p class="text-muted small mb-1">Amount:</p>
                <div class>
                  <h5>{{ data.price }}</h5>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div>
                <p class="text-muted small mb-1">Tax:</p>
                <div class>
                  <h5>{{ data.gst_amount }}</h5>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div>
                <p class="text-muted small mb-1">Total Amount:</p>
                <div class>
                  <h5>{{ data.total_amount }}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-0 row mb-4">
            <div class="col-4">
              <div>
                <p class="text-muted small mb-1">Description:</p>
                <div class>
                  <h5>{{ data.purchase_description }}</h5>
                </div>
              </div>
            </div>
            <div class="col-6 text-right">
              <vs-button @click.prevent.native="showImages(data.purchase_image)"
                >View Images</vs-button
              >
            </div>
          </div>

          <div class="mx-0 row mb-6">
            <div class="col-12">
              <div class="vx-col w-full">
                <p
                  class="text-muted small mb-1"
                  v-for="(data1, index1) in data.purchase_mapping"
                  :key="index1"
                >
                  <span class="outlevel"
                    >Level {{ data1.level }}:
                    <span class="inlevel">{{ data1.description }}</span></span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :id="'popmodalr' + params.data.purchase_id"
        title="Reject PO"
        :active.sync="popupActive1"
      >
        <div class="text-center">
          <h5>Reason For rejection</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-textarea v-model="rejectionComment" class="mb-4" rows="4" />
              <!-- :disabled="submitStatus" -->
              <vs-button
                color="success"
                type="filled"
                @click="onSubmit()"
                class="mr-3"
                >Submit</vs-button
              >

              <vs-button color="danger" type="filled" @click="privStatus()"
                >Cancel</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :id="'popmodalr' + params.data.purchase_id"
        title="Reject PO"
        :active.sync="popupActive1"
      >
        <div class="text-center">
          <h5>Reason For rejection</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-textarea v-model="rejectionComment" class="mb-4" rows="4" />
              <!-- :disabled="submitStatus" -->
              <vs-button
                color="success"
                type="filled"
                @click="onSubmit()"
                class="mr-3"
                >Submit</vs-button
              >

              <vs-button color="danger" type="filled" @click="privStatus()"
                >Cancel</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :id="'popmodalr' + params.data.purchase_id"
        title="Accept PO"
        :active.sync="popupActive2"
      >
        <div class="text-center">
          <h5>Reason For Accept (Exceeded Budget) PO</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-textarea v-model="acceptComment" class="mb-4" rows="4" />

              <vs-button
                color="success"
                type="filled"
                @click="onSubmitPO()"
                class="mr-3"
                >Submit</vs-button
              >

              <vs-button color="danger" type="filled" @click="privStatus()"
                >Cancel</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :id="'popmodalr' + params.data.purchase_id"
        title="Accept PO"
        :active.sync="popupActive3"
      >
        <div class="text-center">
          <h5 v-if="String(params.data.approver_id) == '0'">
            Reason For Accept Expense
          </h5>
          <h5 v-else>Reason For Accept PO</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-textarea v-model="acceptComment" class="mb-4" rows="4" />

              <vs-button
                color="success"
                type="filled"
                @click="onSubmitPO()"
                class="mr-3"
                >Submit</vs-button
              >

              <vs-button color="danger" type="filled" @click="privStatus()"
                >Cancel</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :id="'popmodalr' + params.data.purchase_id"
        title="Accept PO"
        :active.sync="popupActive4"
      >
        <div class="text-center">
          <h5 v-if="String(params.data.approver_id) == '0'">
            Reason For Resubmitting
          </h5>
          <h5 v-else>Reason For Resubmitting</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-textarea v-model="acceptComment" class="mb-4" rows="4" />

              <vs-button
                color="success"
                type="filled"
                @click="submitForm('resubmit=1')"
                class="mr-3"
                >Submit</vs-button
              >

              <vs-button color="danger" type="filled" @click="privStatus()"
                >Cancel</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
    <!-- <vs-popup class="holamundo" title="Lorem ipsum dolor sit amet" :active.sync="Testpopup">
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
    </vs-popup>-->
  </div>
</template>

<script>
import Vue from "vue";
import departmentService from "@/services/departmentService.js";
import purchaseOrderService from "@/services/purchaseOrderService.js";
import projectService from "@/services/projectService.js";
import CategoryService from "@/services/categoryService.js";
import CountryService from "@/services/countryService.js";
import Datepicker from "vuejs-datepicker";

import imagePopUp from "./imagePopUp.vue";
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import moment from "moment";
import MethodService from "@/services/methodService.js";
//
import _ from "lodash";

export default Vue.extend({
  props: {
    editPo1ParamsData: Object,
  },

  data() {
    //:::
    return {
      tempToken: window.localStorage.getItem("TempToken"),
      extensions: ["jpg", "JPG", "png", "PNG", "jpeg", "JPEG"],
      swiperOption: {
        slidesPerView: 1,
        autoHeight: true,
        spaceBetween: 30,
        zoom: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      images: [],
      popupActiveImages: false,
      expenses_datas: [],
      expenses_create_by: "",
      showlog: false,
      showresubmit: false,
      gstList: [],
      mod_user: [],
      LoginUserId: "",
      userLevelLogin: "",
      admin_user: [],
      purchaseData: {},
      rejectionComment: "",
      acceptComment: "",
      popupActive: false,
      price: null,
      is_paid: null,
      tableRefresData: {},
      popupActive0: false,
      popupActive1: false,
      popupActive2: false,
      popupActive3: false,
      popupActive4: false,
      submitStatus: false,
      params: null,
      value: null,
      count: 0,
      user_id: "",
      purchase_status: "",
      map_id: "",
      pmap_id: "",
      project_id: "",
      purchase_id: "",
      form: {
        parent_id: "",
        category_id: "",
      },
      isPopup: null,
      POuser: "",
      acceptReason: "",
      levels: [],
      countries: [],
      cities: [],
      country_value: [],
      city_value: [],
      project_value: [],
      allProjects: [],
      // allCategories: [],
      // sub_categories: [],
      categories: [],
      subcategories: [],
      categoryValue: null,
      subcategoryValue: null,
      hideIcon: false,
      // Testpopup: false
    };
  },
  components: {
    Datepicker,
    swiper,
    swiperSlide,
    // 'vue-pdf-viewer': VuePDFViewer
  },
  watch: {
    popupActive: function(val) {
      if (this.popupActive == false) {
        setTimeout(() => {
          eventBus.$emit("refreshPOList", this.tableRefresData);
        }, 0.5);
      }
    },
  },
  beforeMount() {
    if (this.editPo1ParamsData) {
      this.params = this.editPo1ParamsData;
    }
    this.purchase_status = this.params.data.purchase_status;
    this.form = this.params.data;
    this.proj_id = this.params.data.project_id;
    this.isPopup = this.params.data.isPopup;
    this.is_paid = this.params.data.is_already_paid;
    if (this.editPo1ParamsData) {
      this.hideIcon = true;
      this.showModal();
    }

    // this.getMasterList();
    // this.UserRole = window.localStorage.getItem("UserRole");
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodale" + this.params.data.purchase_id ||
        "popmodalr" + this.params.data.purchase_id
    );
    _id.setAttribute("style", "display: none;");
  },
  computed: {
    UserRole() {
      return localStorage.getItem("UserRole") || null;
    },
  },
  methods: {
    downloadPdf(img, tempToken) {
      console.log(img + "?token=" + window.localStorage.getItem("TempToken"));

      window.open(img + "?token=" + window.localStorage.getItem("TempToken"));
    },
    onClickCloseButton() {
      let obj = {
        budget_id: this.params.data.budget_id,
        show: true,
        budget_code: this.params.data.budget_code,
      };
      console.log(obj);
      eventBus.$emit(
        "showPopupExpense" + this.params.data.budget_code + "Code",
        obj
      );
      eventBus.$emit(
        "showPopupExpense" + this.params.data.budget_id + "Category",
        obj
      );
    },
    showImages: function(images) {
      this.images = images.split(",");
      console.log(this.images, "this.images");
      this.popupActiveImages = true;
    },
    showLogs: function() {
      console.log("showLogs => ", this.purchase_id);
      this.$vs.loading();
      purchaseOrderService
        .showLogs(this.params.data.purchase_id)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          //:::
          this.expenses_create_by = data.purchase_created_by;

          console.log(data.data);
          this.expenses_datas = data.data;

          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
      this.popupActive0 = true;
    },
    getMasterList: function() {
      MethodService.getAllMaster()
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.gstList = data.data.gst;
            let Arry = this.gstList.filter((x) => {
              return x.gst_id == this.purchaseData.gst_id;
            });
            if (Arry.length > 0) {
              let obj = Arry[0];

              this.purchaseData.gst_id = obj;
            }

            // console.log(this.currencyList);
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    gstCalculate(selected) {
      let amount =
        this.purchaseData.total != ""
          ? parseFloat(this.purchaseData.total).toFixed(2)
          : 0;
      let totalAmount =
        (parseFloat(selected.gst_percentage).toFixed(2) * amount) / 100;
      console.log(totalAmount);
      this.purchaseData.gst_amount = parseFloat(totalAmount).toFixed(2);
    },
    showModal() {
      this.$vs.loading();
      this.showLogs();
      // this.popmodalViewR = true;
      // this.getMasterList();
      // this.getProjectList();
      // this.getCategoryList();
      // this.getCountryList();
      // this.getCityList();

      // this.getPurchaseMapDetails();
    },
    getPurchaseMapDetails() {
      this.$vs.loading();
      purchaseOrderService
        .getPurchaseOrderMaping(this.params.data.purchase_id)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status) {
            this.levels = [];
            // console.log("data :", response.data);
            // response.data.data.map(user => {
            //   if (user.mod == 1) {
            //     this.mod_user = user;
            //   }
            //   if (user.mod == 0 && user.user_level == 0) {
            //     this.admin_user = user;
            //   }
            // });
            // let levelGroup = _.groupBy(response.data.data, "user_level");
            // this.admin = levelGroup[0];
            // delete levelGroup[0];
            // this.levels = levelGroup;
            // console.log("this.levels :", this.levels);

            this.purchaseMapDetails = data.data;
            console.log(data, "data.data", data.showresubmit);
            this.showresubmit = data.showresubmit;
            this.showlog = data.showlog;

            //  MODERATOR
            let ModArray = data.data.filter(({ mod }) => String(mod) === "1");

            let otherArray = data.data
              .filter(({ mod, user_level }) => {
                console.log(String(mod), user_level);
                return (
                  String(mod) !== "1" &&
                  (String(mod) !== "0" || String(user_level) !== "0")
                );
              })
              .reverse();

            this.mod_user = ModArray.length > 0 ? ModArray : this.mod_user;

            //  ADMIN
            let AdminArray = data.data.filter(
              ({ mod, user_level }) => String(mod) === "2"
            );

            // let otherArrayNew = data.data
            //   .filter(({ mod, user_level }) => {
            //     console.log(
            //       ' String(mod) != "0" && String(user_level) != "0" :',
            //       String(mod) != "0",
            //       String(user_level) != "0"
            //     );
            //     return String(mod) != "0" || String(user_level) != "0";
            //   })
            //   .reverse();

            this.admin_user =
              AdminArray.length > 0 ? AdminArray : this.admin_user;
            console.log(data, "iuyiuyi");
            this.LoginUserId = data.loggedInUserId;
            this.userLevelLogin = data.user_level;
            // this.userLevelLogin = data.data.filter(x => {
            //   return x.user_id == data.user_id;
            // })[0]["user_level"];

            // response.data.data.map(user => {
            //   if (user.mod == 1) {
            //     this.mod_user = user;
            //   }
            //   if (user.mod == 0 && user.user_level == 0) {
            //     this.admin_user = user;
            //   }
            // });
            // let levelGroup = _.groupBy(response.data.data, "user_level");
            console.log(otherArray, "DGDSFGDS");
            let levelGroup = _.groupBy(otherArray, "user_level");

            console.log(
              _.groupBy(otherArray, "user_level"),
              levelGroup,
              "fsdfdsf"
            );

            // this.admin = levelGroup[0];
            // delete levelGroup[0];
            let newArr = [];
            let reverse = Object.keys(levelGroup).reverse();
            this.levels = [];
            reverse.map((x) => {
              console.log("x :", x);
              this.levels.push({
                levelName: x,
                level: levelGroup[parseInt(x)],
              });
            });

            console.log(this.levels);
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
    getProjectList: function() {
      projectService
        .getAllProjects()
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status) {
            this.allProjects = data.data;
            this.allProjects.map((x) => {
              if (this.params.data.project_id == x.project_id) {
                this.project_value = x;
              }
            });
          } else {
            // TODO Show Error Message, Whcih is comming from backend
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error!",
            // text: data.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    // getCategoryList: function() {
    //   let id = this.proj_id;
    //   CategoryService.getProjectMainCategories(id)
    //     .then(response => {
    //       const { data } = response;
    //       if (data.Status) {
    //         this.allCategories = data.data;
    //         this.allCategories.map(x => {
    //           if (this.params.data.category_id == x.category_id) {
    //             this.category_value = x;
    //           }
    //         });
    //       } else {
    //         this.$vs.notify({
    //           title: "Categories not found",
    //           // text: data.Message,
    //           iconPack: "feather",
    //           icon: "check_box",
    //           color: "warning"
    //         });
    //       }
    //     })
    //     .catch(error => {
    //       this.$vs.notify({
    //         title: "Error!1",
    //         // text: data.Message,
    //         iconPack: "feather",
    //         icon: "check_box",
    //         color: "warning"
    //       });
    //     });
    // },
    getCategoryList: function() {
      let ID = this.proj_id;
      CategoryService.getProjectMainCategories(ID)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data["data"] && data.Status) {
            this.categories = data.data;
            this.categories.map((x) => {
              if (this.params.data.parent_id === x.category_id) {
                this.categoryValue = x;
                this.form.parent_id = x.category_id;
                this.getSubcategoryList(x.category_id);
              }
            });
          } else {
            this.$vs.notify({
              title: "Error!",
              text: "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    onSuggestSelect: function(selected) {
      this.getSubcategoryList(selected.category_id || null);
    },
    getSubcategoryList: function(ID) {
      let PId = this.proj_id;
      CategoryService.getSubCategoriesByParentCategory(ID, PId)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.subcategories = data.data;
            this.subcategories.map((x) => {
              if (this.params.data.category_id === x.category_id) {
                this.subcategoryValue = x;
                this.form.category_id = x.category_id;
              }
            });
            if (data.data.length == 1) {
              this.subcategoryValue = this.subcategories[0];
            } else {
              this.subcategoryValue = "";
            }
          } else {
            this.$vs.notify({
              title: "Error!",
              text: "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: "No Data Found",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    onSelectCountry({ country_id }) {
      this.getCityList(country_id);
    },
    getCountryList: function() {
      CountryService.getAllCountry()
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status) {
            this.countries = data.data;
            this.countries.map((x) => {
              if (this.params.data.country_id == x.country_id) {
                this.country_value = x;
              }
            });
          } else {
            // TODO Show Error Message, Whcih is comming from backend
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
        });
    },
    getCityList: function(id) {
      this.cities = [];
      this.city_value = null;
      this.city_id = null;
      CountryService.getCityByCountry(id ? id : this.params.data.country_id)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status) {
            this.cities = data.data;
            this.cities.map((x) => {
              if (this.params.data.city_id == x.city_id) {
                this.city_value = x;
              }
            });
          } else {
            // TODO Show Error Message, Whcih is comming from backend
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
        });
    },

    // getSubCategoryList: function(categoryID) {
    //   console.log("categoryID :", categoryID);
    //   let payload = {
    //     categoryID: categoryID
    //   };
    //   CategoryService.getSubCategoryByCategory(payload).then(response => {
    //     const data = response.data;
    //     if (!data.error && data) {
    //       this.sub_categories = data.data;
    //       this.sub_categories.map(x => {
    //         if (this.params.data.sub_category_id == x.sub_category_id) {
    //           this.subcategoy_value = x;
    //         }
    //       });
    //       // console.log("data :", data);
    //       // this.categories = data.data;
    //     } else {
    //       // Show Error Message, Whcih is comming from backend5
    //     }
    //   });
    // },
    submitForm: function(resubmit = "resubmit=0") {
      let bol = true;
      if (resubmit == "resubmit=1") {
        if (this.acceptComment.length > 0) {
          this.acceptReason = this.acceptComment;
          // this.approvePurchaseOrder(this.POuser, this.acceptReason);
        } else {
          bol = false;
          this.$vs.notify({
            title: "Please provide a reason!",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        }
      }
      if (bol) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.popupActive4 = false;
            let payload = {
              reason: this.acceptReason,
              project_id: this.purchaseData.project_id,
              user_id: this.purchaseData.user_id,
              po_name: this.purchaseData.po_name,
              item_name: this.purchaseData.item_name,
              company_name: this.purchaseData.company_name,
              company_address: this.purchaseData.company_address,
              price: this.purchaseData.total,
              currency: this.purchaseData.currency,
              VHC_code: this.purchaseData.VHC_code,
              sgst: "1",
              cgst: "1",
              total: this.purchaseData.total,
              purchase_image: this.purchaseData.purchase_image,
              parent_id: this.form.parent_id,
              category_id: this.form.category_id,
              city_id: this.city_value.city_id,
              country_id: this.country_value.country_id,
              purchase_description: this.purchaseData.purchase_description,
              purchase_status: this.purchaseData.purchase_status,
              approved_percentage: this.purchaseData.approved_percentage,
              purchase_active: this.purchaseData.purchase_active,
              invoice_number: this.purchaseData.invoice_number,

              invoice_date: this.purchaseData.invoice_date
                ? moment(this.purchaseData.invoice_date).format("YYYY-MM-DD")
                : "",

              payment_due_date: this.purchaseData.payment_due_date
                ? moment(this.purchaseData.payment_due_date).format(
                    "YYYY-MM-DD"
                  )
                : "",
              gst_amount: this.purchaseData.gst_amount,
              gst_id: this.purchaseData.gst_id
                ? this.purchaseData.gst_id.gst_id
                : this.purchaseData.gst_id.gst_id,
              total_amount: this.purchaseData.total_amount,
              vendor_id: this.purchaseData.vendor_id,
            };
            console.log(resubmit, "resubmit");

            purchaseOrderService
              .editPurchaseOrder(
                payload,
                this.params.data.purchase_id,
                resubmit
              )
              .then((response) => {
                const { data } = response;
                if (data.Status == true) {
                  this.$vs.notify({
                    title: "Updated!",
                    text: data.Message,
                    iconPack: "feather",
                    icon: "check_box",
                    color: "success",
                  });
                  eventBus.$emit("refreshPOList", data);
                } else {
                  this.$vs.notify({
                    title: "Error!",
                    text: data.Message,
                    iconPack: "feather",
                    icon: "check_box",
                    color: "warning",
                  });
                }
              })
              .catch((err) => {});
          }
        });
      }
    },
    approvePO: function(user, type) {
      // this.Testpopup = true;
      if (type == ("user", "admin") && this.isPopup == 1) {
        this.POuser = user;
        this.popupActive2 = true;
      } else {
        this.POuser = user;
        this.popupActive3 = true;
        // this.approvePurchaseOrder(user);
      }
    },

    onSubmitPO: function() {
      if (this.isPopup == 1) {
        if (this.acceptComment.length > 0) {
          this.acceptReason = this.acceptComment;
          this.approvePurchaseOrder(this.POuser, this.acceptReason);
        } else {
          this.$vs.notify({
            title: "Please provide a reason!",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        }
      } else {
        this.acceptReason = this.acceptComment;
        this.approvePurchaseOrder(this.POuser, this.acceptReason);
      }
    },

    approvePurchaseOrder: function(user, reason) {
      let obj = {
        project_id: user.project_id,
        purchase_id: this.params.data.purchase_id,
        user_id: user.user_id,
        reason: reason,
      };
      purchaseOrderService
        .approvePurchaseOrderDashboard(obj)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.getPurchaseMapDetails();
            this.$vs.notify({
              title: "Success",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });

            this.popupActive2 = false;
            this.popupActive3 = false;
            this.acceptComment = "";
            this.acceptReason = "";
            this.tableRefresData = data;
            // setTimeout(() => {
            //   eventBus.$emit("refreshPOList", data);
            // }, 1);
            // this.purchaseData = data.data;
          } else {
            this.$vs.notify({
              title: "OOPS!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        });
    },
    resubmitconformation() {
      this.popupActive4 = true;
    },
    privStatus: function() {
      this.popupActive1 = false;
      this.popupActive2 = false;
      this.popupActive3 = false;
      this.popupActive4 = false;
      this.popupActiveImages = false;
      this.popupActive0 = false;
    },
    onSubmit: function() {
      if (this.rejectionComment.length > 0) {
        let obj = {
          project_id: this.project_id,
          purchase_id: this.params.data.purchase_id,
          user_id: this.user_id,
        };
        purchaseOrderService
          .rejectPurchaseOrderDashboard(obj, {
            reason: this.rejectionComment,
          })
          .then((response) => {
            const { data } = response;
            if (data.Status == true) {
              this.popupActive1 = false;
              this.rejectionComment = "";
              this.user_id = "";
              this.map_id = "";
              this.getPurchaseMapDetails();
              this.$vs.notify({
                title: "Rejected!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "success",
              });
              this.tableRefresData = data;
              // setTimeout(() => {
              //   eventBus.$emit("refreshPOList", data);
              // }, 1);
              // this.$router.push({
              // path: "/user-po/?tabIndex=0"
              // });
              // this.purchaseData = data.data;
            } else {
              this.$vs.notify({
                title: "OOPS!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            }
          });
      } else {
        this.$vs.notify({
          title: "Please provide a reason!",
          // text: data.error.message,
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }
    },
    rejectPO: function(user) {
      this.user_id = user.user_id;
      this.purchase_id = user.purchase_id;
      this.project_id = user.project_id;
      this.popupActive1 = true;
    },
  },
  mounted() {
    const next = this.$children[0].$refs.btnclose;
    next.$el.addEventListener("click", this.onClickCloseButton, false);

    this.purchase_id = this.params.data.purchase_id;
    this.purchaseData = this.params.data;

    // purchaseService.getPurchaseOrderMaping(this.purchase_id).then(data => {
    //   const { data } = response;
    //   if (!data.error) {
    //     console.log("data :", data);
    //   }
    // });
  },
});
</script>
<style>
.po-popup .vs-popup--content {
  height: 100%;
}

.popmodalViewR .vs-popup {
  width: 70% !important;
}
.outlevel {
  margin-right: 10px;
}
.inlevel {
  font-weight: bold;
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  overflow: hidden;
}
.greyme {
  background-color: grey;
  cursor: default;
}
.sb-border {
  border-bottom: solid;
  margin-bottom: 20px;
}
</style>
