<template>
  <div class="center-icon">
    <span
      class="text-center dot-edit-icon"
      :class="
        priority.toLowerCase() == 'low'
          ? 'low'
          : priority.toLowerCase() == 'high'
          ? 'high'
          : 'normal'
      "
    ></span>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      params: null,
      priority: "",
    };
  },
  beforeMount() {
    this.priority = this.params.value;
  },
});
</script>
<style>
.dot-edit-icon {
  height: 24px;
  width: 24px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.dot-edit-icon.low {
  background-color: #ffc107;
}

.dot-edit-icon.high {
  background-color: #dc3545;
}

.dot-edit-icon.normal {
  background-color: #ffffff;
}
</style>
