<template>
  <div class="">
    <b-carousel
      id="carousel"
      v-model="slide"
      controls
      indicators
      style="text-shadow: 1px 1px 2px #333;"
      background="#ababab"
      img-width="1024"
      img-height="700"
    >
      <b-carousel-slide
        img-blank
        v-for="(attachment, index) in attachments"
        :key="'attachment' + index"
      >
        <div class="flex flex-col items-center">
          <b-img
            v-if="
              imageExtensions.indexOf(
                attachment.split('.')[attachment.split('.').length - 1]
              ) >= 0
            "
            width="250"
            :src="attachment"
            fluid
            alt="Purchase Attachment"
          />

          <img
            class="block mx-auto h-32"
            src="@/assets/images/pdf-icon.png"
            v-else-if="attachment.split('.').slice(-1)[0] == 'pdf'"
          />

          <img
            class="block mx-auto "
            src="@/assets/images/docx_icon.png"
            v-else-if="
              attachment.split('.').slice(-1)[0] == 'docx' ||
                attachment.split('.').slice(-1)[0] == 'doc'
            "
          />

          <img
            class="block mx-auto h-32"
            src="@/assets/images/excelLogo.png"
            v-else
          />

          <vs-button
            color="primary"
            type="filled"
            :href="attachment"
            target="_blank"
            class="mt-10"
          >
            Preview
          </vs-button>
        </div>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import { BCarousel, BCarouselSlide, BImg } from "bootstrap-vue";
export default {
  components: {
    BCarousel,
    BCarouselSlide,
    BImg,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      slide: null,
      attachments: [],
      ignored: [],
      imageExtensions: [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
        "tiff",
        "psd",
        "svg",
        "raw",
        "webp",
        "heic",
      ],
    };
  },

  watch: {
    data() {
      this.prepareAttachments();
    },
  },

  methods: {
    prepareAttachments() {
      this.attachments = this.data.purchase_image
        ? this.data.purchase_image
            .split(",")
            .filter((item) => item && item != "-")
        : [];
      this.ignored = this.data.ignored_image
        ? this.data.ignored_image
            .split(",")
            .filter((item) => item && item != "-")
        : [];

      if (this.ignored && this.ignored.length > 0) {
        this.attachments = this.attachments.concat(this.ignored);
      }
    },
  },

  mounted() {
    this.prepareAttachments();
  },
};
</script>

<style></style>
